<template>
  <b-row class="justify-content-sm-start justify-content-md-center justify-content-lg-end">
    <b-col cols="1" class="d-none d-md-block d-lg-none"></b-col>
    <b-col
      cols="6" sm="6" md="3" lg="auto"
      class="footer-bar-top-row__links-section"
      v-for="(linkSection, index) in model"
      :key="index"
    >
      <div>
        <h5 class="footer-bar-top-row--title">{{linkSection.title}}</h5>
        <ul>
          <li v-for="(link,index) in linkSection.links" :key="index">
            <b-link
              :href="link.url"
              :target="link.target"
              :class="'footer-bar-top-row__links--item'"
            >{{link.text}}</b-link>
          </li>
        </ul>
      </div>
    </b-col>
  </b-row>
</template>
<script>
export default {
  name: "FooterLinkSection",
  props: {
    model: Array
  }
};
</script>