<template>
  <div class="social-feed-tile">
    <div>
      <div v-if="dto.image" class="social-feed-tile__img-container">
        <img :src="dto.image" />
      </div>
      <div class="social-feed-tile__description-container">
        <p>
          <strong v-text="dto.title"></strong>
        </p>
        <p class="text-left">
          <strong v-text="dto.user.displayName"></strong>
          <small v-if="dto.user && dto.user.login" class="ml-1" v-text="'@'+dto.user.login"></small>
        </p>
        <p class="social-feed-tile__description-text" v-html="dto.description" v-linkified></p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SocialFeedTile",
  components: {},
  mounted() {},
  data() {
    return {};
  },
  props: {
    dto: Object
  },
  computed: {},
  methods: {}
};
</script>
<style lang="scss">
@import "@/scss/_variables";
.social-feed-tile {
  width: 100%;
  -webkit-column-break-inside: avoid;
  -moz-column-break-inside: avoid;
  break-inside: avoid-column;
  display: inline-block;
  border-style: solid;
  border-color: $lighten-gray;
  border-width: 0.0625rem;
  border-radius: 0.625rem;
  background-color: $white;

  margin: 0.625rem 0;
  &__img-container {
    max-width: 100%;
    max-height: 12.5rem;
    overflow: hidden;
    img {
      width: 100%;
      border-radius: 0.5rem;
    }
  }
  &__description-container {
    padding: 1px 5px 0 5px;
  }
  &__description-text {
    margin-top: 0.625rem;
    text-align: justify;
    font-size: 13px;
  }
}
</style>
