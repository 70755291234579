<template>
  <div>
    <b-navbar toggleable="lg" class="navigation-container">
      <b-container class="navigation-bar">
        <b-navbar-brand href="/" class="navigation-bar__logo align-self-top">
          <b-img :src="navigation.logo.url" :alt="navigation.logo.text"></b-img>
        </b-navbar-brand>
        <div>
          <b-button
              v-if="navigation.donationLink"
              class="d-lg-none navigation-bar__donation-link"
              variant="success"
              :href="navigation.donationLink.url"
          >{{ navigation.donationLink.text }}
          </b-button>
          <b-navbar-toggle target="nav-collapse">
            <b-img :src="`${publicPath}/icons/menu.png`" width="30" height="27"></b-img>
          </b-navbar-toggle>
        </div>

        <b-collapse id="nav-collapse" class="navigation-bar__links" is-nav>
          <b-row class="navigation-bar__links--row">
            <b-col cols="12">
              <b-navbar-nav class="ml-auto float-right navigation-bar__links-top d-none d-lg-block">
                <navigation-element
                    v-bind:key="index"
                    :item="navItem"
                    v-for="(navItem,index) in navigation.topNavigationRow"
                    class="navigation-bar__links-top--item"
                ></navigation-element>
              </b-navbar-nav>
            </b-col>
            <b-col cols="12">
              <b-navbar-nav class="ml-auto float-right navigation-bar__links-bottom">
                <navigation-element
                    v-bind:key="index"
                    :item="navItem"
                    v-for="(navItem,index) in navigation.mainNavigationRow"
                    class="navigation-bar__links-bottom--item"
                ></navigation-element>
              </b-navbar-nav>
            </b-col>
          </b-row>
        </b-collapse>
      </b-container>
    </b-navbar>
  </div>
</template>

<script>
import NavigationElement from "./NavigationElement";
import {
  BNavbar,
  BNavbarBrand,
  BCollapse,
  BNavbarNav,
  BNavbarToggle
} from "bootstrap-vue";

export default {
  name: "NavigationBar",
  components: {
    NavigationElement,
    BNavbar,
    BNavbarBrand,
    BCollapse,
    BNavbarNav,
    BNavbarToggle
  },
  props: {
    navigation: Object
  },
  computed: {
    publicPath() {
      return this.$store.ConfigStore.getters.rootPath;
    }
  }
};
</script>
<style lang="scss">
@import "~@/scss/_variables.scss";
@import "~@/scss/_breakpoints.scss";

.navigation-container {
  @include media-breakpoint-up(lg) {
    min-height: $menu-height;
  }
  width: 100%;
  z-index: 400;
}

.navigation-bar {
  @include media-breakpoint-down(md) {
    max-width: none;
  }

  .navbar-toggler {
    outline: none;
    border: none;
    padding-right: 0;

    img {
      margin-top: 4px;
    }
  }

  &__donation-link {
    margin-right: 5px;
  }

  &__logo {
    img {
      max-height: 39px;
      max-width: 176px;
      @include media-breakpoint-up(lg) {
        max-height: 52px;
        max-width: 207px;
      }
    }
  }

  &__links {
    @include media-breakpoint-down(md) {
      margin-top: 64px;
      position: absolute;
      top: 0;
      left:0;
      right: 0;

      z-index: 500;
      &-bottom {
        background: $light-gray;
        width: 100%;
      }
      &--row{
        width:auto !important;
      }
    }

    &--row {
      width: 100%;
    }
  }
}
</style>