<template>
	<div
		class="statistics-collection d-flex flex-column flex-lg-row justify-content-center row"
	>
		<statistics-tile
			v-for="element in model"
			:key="element.label"
			:model="element"
		></statistics-tile>
	</div>
</template>

<script>
	import StatisticsTile from './StatisticsTile.vue';

	export default {
		name: 'StatisticsCollection',
		components: {
			StatisticsTile,
		},
		data() {
			return {};
		},
		props: {
			model: Array,
		},
	};
</script>

<style lang="scss">
	.statistics-collection {
		div:last-child {
			margin-bottom: 0;
		}
	}
</style>
