import axios from 'axios'

const apiKey = "3a8ab035-b301-474d-92ac-e0451b28caee";
const apiEndpoint = "https://api.edq.com/capture/address/v2/search"

export default {
    getAddressesByPostCode: function (postCode) {
        return axios({
            method: "GET",
            url: apiEndpoint,
            params: {
                query: postCode,
                country: "gbr",
                take: 100,
                'auth-token':apiKey
            }
        });
    },
    getDetails: function (detailsUrl) {
        return axios({
            url: detailsUrl,
            params: {'auth-token': apiKey}
        }).then((response) => {
            if (response.data.address[4].province.length) {
                response.data.address[1].addressLine3 = response.data.address[1].addressLine3.length > 0
                    ? `${response.data.address[1].addressLine3}, ${response.data.address[4].province}`
                    : response.data.address[4].province
            }

            return {
                addressLine1: response.data.address[0].addressLine1,
                addressLine2: response.data.address[1].addressLine2,
                addressLine3: response.data.address[2].addressLine3,
                locality: response.data.address[3].locality,
                postCode: response.data.address[5].postalCode,
                country: response.data.address[6].country,
            }
        });
    }
}