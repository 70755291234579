<template>
  <div class="volunteer-registration-form">
    <b-form>
      <b-row align-h="center">
        <b-col md="10" cols="12">
          <b-row>
            <b-col class="title">
              <h1>
                {{
                  model.translations["volunteerRegistration.Form.MainHeader"]
                }}
              </h1>
              <h6>
                {{ model.translations["volunteerRegistration.Form.Header"] }}
              </h6>
            </b-col>
          </b-row>
          <hr />
          <b-row>
            <b-col cols="12" lg="6">
              <b-form-group
                id="firstNameSet"
                :description="
                  model.translations['volunteerRegistration.Form.FirstName']
                "
              >
                <label for="firstName"
                  >{{
                    model.translations[
                      "volunteerRegistration.Form.VolunteerName"
                    ]
                  }}
                  <span class="text-danger">*</span></label
                >
                <b-form-input
                  id="firstName"
                  name="firstName"
                  type="text"
                  aria-describedby="invalidName"
                  :placeholder="
                    model.translations['volunteerRegistration.Form.FirstName']
                  "
                  v-model="$v.state.firstName.$model"
                  :state="firstNameState"
                  @input="
                    validateTextInput(
                      $v.state.firstName.$model,
                      FormParameter.FIRSTNAME
                    )
                  "
                  required
                ></b-form-input>

                <b-form-invalid-feedback id="invalidName">
                  {{ model.translations["generic.FieldRequired"] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col cols="12" lg="6">
              <b-form-group
                id="lastNameSet"
                :description="
                  model.translations['volunteerRegistration.Form.LastName']
                "
                style="margin-top: 32px"
              >
                <b-form-input
                  id="lastName"
                  name="lastName"
                  v-model="$v.state.lastName.$model"
                  type="text"
                  :placeholder="
                    model.translations['volunteerRegistration.Form.LastName']
                  "
                  :state="lastNameState"
                  @input="
                    validateTextInput(
                      $v.state.lastName.$model,
                      FormParameter.LASTNAME
                    )
                  "
                  required
                ></b-form-input>
                <b-form-invalid-feedback id="invalidName">
                  {{ model.translations["generic.FieldRequired"] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="12" lg="6">
              <b-form-group
                id="contactNumberSet"
                :description="
                  model.translations['volunteerRegistration.Form.ContactHelper']
                "
              >
                <label for="contactNumber"
                  >{{
                    model.translations[
                      "volunteerRegistration.Form.ContactNumber"
                    ]
                  }}
                  <span class="text-danger">*</span></label
                >
                <b-form-input
                  id="contactNumber"
                  name="contactNumber"
                  type="tel"
                  :placeholder="
                    model.translations[
                      'volunteerRegistration.Form.ContactInputPlaceholder'
                    ]
                  "
                  v-model="$v.state.contactNumber.$model"
                  :state="contactNumberState"
                  @input="validateContactNumber($v.state.contactNumber.$model)"
                  required
                ></b-form-input>
                <b-form-invalid-feedback id="invalid">
                  {{ model.translations["generic.FieldRequired"] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="12" lg="6">
              <b-form-group
                id="emailSet"
                :description="
                  model.translations['volunteerRegistration.Form.EmailHelper']
                "
              >
                <label for="email"
                  >{{
                    model.translations[
                      "volunteerRegistration.Form.EmailAddress"
                    ]
                  }}
                  <span class="text-danger">*</span></label
                >
                <b-form-input
                  name="email"
                  type="email"
                  :state="emailState"
                  @input="validateEmail($v.state.email.$model)"
                  required
                  v-model="$v.state.email.$model"
                  :placeholder="
                    model.translations[
                      'volunteerRegistration.Form.EmailAddress'
                    ]
                  "
                ></b-form-input>
                <b-form-invalid-feedback>
                  {{ model.translations["generic.FieldRequired"] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" lg="6">
              <b-form-group id="postCodeSet">
                <label for="postCode"
                  >{{
                    model.translations["volunteerRegistration.Form.Postcode"]
                  }}
                  <span class="text-danger">*</span></label
                >
                <b-form-input
                  id="postCode"
                  name="postCode"
                  type="text"
                  v-model="$v.state.postcode.$model"
                  :state="postcodeState"
                  @input="
                    validateTextInput(
                      $v.state.postcode.$model,
                      FormParameter.POSTCODE
                    )
                  "
                  required
                ></b-form-input>
                <b-form-invalid-feedback>
                  {{ model.translations["generic.FieldRequired"] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" lg="6">
              <b-form-group id="localCharitySet">
                <label for="localCharity"
                  >{{
                    model.translations[
                      "volunteerRegistration.Form.LocalCharity"
                    ]
                  }}
                  <span class="text-danger">*</span></label
                >
                <b-form-select
                  id="hospice"
                  name="hospice"
                  v-model="$v.state.hospiceId.$model"
                  :options="model.hospiceList"
                  :state="validationState($v.state.hospiceId)"
                  selected="null"
                  required
                >
                  <b-form-select-option value="null" disabled
                    >{{
                      model.translations[
                        "volunteerRegistration.Form.SelectHospice"
                      ]
                    }}
                  </b-form-select-option>
                </b-form-select>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" lg="6">
              <b-form-group id="nextOfKinSet">
                <label for="nextOfKin"
                  >{{
                    model.translations["volunteerRegistration.Form.NextOfKin"]
                  }}
                  <span class="text-danger">*</span></label
                >
                <b-form-input
                  id="nextOfKin"
                  name="nextOfKin"
                  type="text"
                  v-model="$v.state.nextOfKin.$model"
                  placeholder="Name"
                  :state="nextOfKinState"
                  @input="
                    validateTextInput(
                      $v.state.nextOfKin.$model,
                      FormParameter.NEXTOFKIN
                    )
                  "
                  required
                ></b-form-input>
                <b-form-invalid-feedback id="invalidName">
                  {{ model.translations["generic.FieldRequired"] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" lg="6">
              <b-form-group
                id="nextOfKinContactNumberSet"
                :description="
                  model.translations['volunteerRegistration.Form.ContactHelper']
                "
              >
                <label for="nextOfKinContactNumber"
                  >{{
                    model.translations[
                      "volunteerRegistration.Form.NextOfKinContactNumber"
                    ]
                  }}
                  <span class="text-danger">*</span></label
                >
                <b-form-input
                  id="nextOfKinContactNumber"
                  name="nextOfKinContactNumber"
                  type="tel"
                  v-model="$v.state.nextOfKinContactNumber.$model"
                  :placeholder="
                    model.translations[
                      'volunteerRegistration.Form.ContactInputPlaceholder'
                    ]
                  "
                  :state="nextOfKinContactNumberState"
                  @input="
                    validateNextOfKinContactNumber(
                      $v.state.nextOfKinContactNumber.$model
                    )
                  "
                  required
                ></b-form-input>
                <b-form-invalid-feedback id="invalid">
                  {{ model.translations["generic.FieldRequired"] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <h3>Privacy Policy</h3>
          </b-row>
          <hr />
          <b-row>
            <b-col>
              <b-form-checkbox
                id="isPhotoUsageConfirmed"
                :v-model="$v.state.isPhotoUsageConfirmed"
                name="isPhotoUsageConfirmed"
                @change="setPhotoUsageCheckbox"
              >
                {{
                  model.translations[
                    "volunteerRegistration.Form.OptionalPrivacyPolicy"
                  ]
                }}
              </b-form-checkbox>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <label for="isObligationConfirmed"
                ><span class="text-danger">*</span></label
              >
              <b-form-checkbox
                id="isObligationConfirmed"
                :v-model="$v.state.isObligationConfirmed"
                name="isObligationConfirmed"
                :state="checkboxState"
                @change="setObligationCheckbox"
                required
              >
                {{
                  model.translations[
                    "volunteerRegistration.Form.ObligatoryPrivacyPolicy"
                  ]
                }}
              </b-form-checkbox>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <label for="isCodeOfConductConfirmed"
                ><span class="text-danger">*</span></label
              >
              <b-form-checkbox
                id="isCodeOfConductConfirmed"
                :v-model="$v.state.isCodeOfConductConfirmed"
                :disabled="!isCodeOfConductLinkClicked"
                name="isCodeOfConductConfirmed"
                :state="codeOfConductCheckboxState"
                @change="setCodeOfConductCheckbox"
                required
              >
                <span>
                  I agree to adhere to the
                  <a
                    href="/static/pdfs/CodeOfConduct.pdf"
                    target="_blank"
                    @click="codeOfConductLinkClicked"
                    >Volunteer Code of Conduct & Health and Safety Policies</a
                  >. NOTE: Volunteer registration will not be validated until
                  these policies have been viewed.
                </span>
              </b-form-checkbox>
            </b-col>
          </b-row>
          <b-row align-h="end">
            <b-col cols="12" lg="6" class="text-center">
              <b-button
                type="submit"
                variant="primary"
                class="button--large"
                @click.prevent="submit"
                >Submit
              </b-button>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-form>
    <b-alert
      v-model="hasValidationError"
      class="position-fixed fixed-top m-0 rounded-0"
      style="z-index: 2000; text-align: center"
      variant="danger"
      dismissible
    >
      Check all the inputs are correct.
    </b-alert>
  </div>
</template>

<script>
import { load } from "recaptcha-v3";
import ValidationMixin from "@/mixins/validationMixin";
import { required, email } from "vuelidate/lib/validators";
import { nameFieldMaxLength } from "@/domain/constants";

import { BAlert } from "bootstrap-vue";

export default {
  name: "VolunteerRegistrationForm",
  mixins: [ValidationMixin],
  components: {
    BAlert,
  },
  mounted() {
    this.maxNameLength = nameFieldMaxLength;
    this.$setBuisy(true);
    load(this.model.reCaptcha.siteKey).then((recaptcha) => {
      this.recaptcha = recaptcha;
      this.$setBuisy(false);
    });

    this.FormParameter = {
      FIRSTNAME: "firstName",
      LASTNAME: "lastName",
      POSTCODE: "postcode",
      NEXTOFKIN: "nextOfKin",
    };
  },

  data() {
    return {
      FormParameter: null,
      maxNameLength: null,
      hasValidationError: false,
      emailState: null,
      contactNumberState: null,
      nextOfKinContactNumberState: null,
      firstNameState: null,
      lastNameState: null,
      nextOfKinState: null,
      postcodeState: null,
      checkboxState: false,
      codeOfConductCheckboxState: false,
      isCodeOfConductLinkClicked: false,
      state: {
        email: null,
        firstName: null,
        lastName: null,
        contactNumber: null,
        postcode: null,
        hospiceId: null,
        nextOfKin: null,
        nextOfKinContactNumber: null,
        isObligationConfirmed: false,
        isCodeOfConductConfirmed: false,
        isPhotoUsageConfirmed: false,
      },
    };
  },
  validations: {
    state: {
      firstName: { required },
      lastName: { required },
      contactNumber: { required },
      postcode: { required },
      hospiceId: { required },
      nextOfKin: { required },
      nextOfKinContactNumber: { required },
      isObligationConfirmed: true,
      isCodeOfConductConfirmed: true,
      email: { required, email },
    },
  },

  methods: {
    scrollToFirstInvalidElement() {
      let element = this.$el.getElementsByClassName("is-invalid")[0];
      if (element != undefined) {
        this.$scrollTo(element, 200, { offset: -80 });
      }
    },

    isValidTextInput(textInput) {
      if (textInput === null || textInput.length < 2) {
        return false;
      }

      return true;
    },

    validateTextInput(textInput, parameter) {
      switch (parameter) {
        case this.FormParameter.FIRSTNAME:
          this.firstNameState = this.isValidTextInput(textInput);
          break;
        case this.FormParameter.LASTNAME:
          this.lastNameState = this.isValidTextInput(textInput);
          break;
        case this.FormParameter.NEXTOFKIN:
          this.nextOfKinState = this.isValidTextInput(textInput);
          break;
        case this.FormParameter.POSTCODE:
          this.postcodeState = this.isValidTextInput(textInput);
          break;
      }
    },

    validateTextInputForSubmission() {
      this.firstNameState = this.state.firstName !== null;
      this.lastNameState = this.state.lastName !== null;
      this.nextOfKinState = this.state.nextOfKin !== null;
      this.postcodeState = this.state.postcode !== null;
      this.emailState = this.state.email !== null;
      this.contactNumberState = this.state.contactNumber !== null;
      this.nextOfKinContactNumberState =
        this.state.nextOfKinContactNumber !== null;
      this.checkboxState = this.state.isObligationConfirmed;
      this.codeOfConductCheckboxState = this.state.isCodeOfConductConfirmed;
    },

    validateEmail(email) {
      const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

      if (emailPattern.test(email)) {
        this.emailState = true;
      } else {
        this.emailState = false;
      }
    },

    validateContactNumber(contactNumber) {
      const contactNumberPattern = /[0-9]{10}/;

      if (contactNumberPattern.test(contactNumber)) {
        this.contactNumberState = true;
      } else {
        this.contactNumberState = false;
      }
    },

    validateNextOfKinContactNumber(contactNumber) {
      const contactNumberPattern = /[0-9]{10}/;

      if (contactNumberPattern.test(contactNumber)) {
        this.nextOfKinContactNumberState = true;
      } else {
        this.nextOfKinContactNumberState = false;
      }
    },

    setPhotoUsageCheckbox(val) {
      this.state.isPhotoUsageConfirmed = val;
    },

    setObligationCheckbox(val) {
      this.state.isObligationConfirmed = val;
      this.checkboxState = val;
    },

    setCodeOfConductCheckbox(val) {
      this.state.isCodeOfConductConfirmed = val;
      this.codeOfConductCheckboxState = val;
    },

    codeOfConductLinkClicked() {
      this.isCodeOfConductLinkClicked = true;
    },

    submit() {
      this.validateTextInputForSubmission();

      if (!this.checkboxState || !this.codeOfConductCheckboxState) {
        alert("Required Privacy Policy must be agreed");
        this.$nextTick(() => this.scrollToFirstInvalidElement());

        return;
      }

      this.$v.$touch();
      if (this.$v.$anyError) {
        this.$nextTick(() => this.scrollToFirstInvalidElement());
        this.hasValidationError = true;
        return;
      }

      this.$setBuisy(true);
      this.recaptcha.execute("register").then((token) => {
        this.$emit("submitForm", this.model, this.state, token);
      });
    },
  },

  props: {
    model: Object,
    genericErrorMessage: String,
  },

  watch: {
    genericErrorMessage() {
      if (this.genericErrorMessage !== "") {
        this.$setBuisy(false);
        alert(this.genericErrorMessage);
      }
    },
  },
};
</script>
<style lang="scss">
@import "~@/scss/_variables.scss";
@import "~@/scss/_breakpoints.scss";

.title {
  text-align: center;
}

.volunteer-registration-form {
  &--smaller-text {
    font-size: 0.875rem;
  }

  &__predefined-donations {
    text-align: center;

    span {
      height: 50px;
      padding: 12px 16px;
      width: 100%;

      @include media-breakpoint-up(lg) {
        min-width: 80px;
      }
    }

    span:last-child {
      margin-right: 0 !important;
    }

    &__item {
      @include media-breakpoint-down(sm) {
        padding-top: 1rem;
      }
    }
  }

  .col-12 {
    @include media-breakpoint-down(md) {
      padding-bottom: 1rem;
    }
  }

  .row {
    @include media-breakpoint-up(lg) {
      padding-bottom: 1rem;
    }
  }

  hr {
    margin-bottom: 1rem;
  }
}
</style>
