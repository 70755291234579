<template>
  <b-row class="register-tree__post-code" id="register-tree-postcode">
    <b-col cols="12" lg="6" class="register-tree__post-code-tile">
      <h2 class="register-tree__post-code-tile-header"
        v-text="dto.translations['registerTree.Form.PostCodeLeftTabHeader']"></h2>
      <small
        v-text="isCollectionOpen ? dto.translations['registerTree.Form.PostCodeLeftTabCollectionOpenInfo'] : dto.translations['registerTree.Form.PostCodeLeftTabCollectionClosedInfo']"></small>
      <div
        class="register-tree__post-code-tile-statistics d-flex flex-column flex-sm-column flex-md-row justify-content-around">
        <div v-for="statistic in dto.statistics" :key="statistic.label">
          <div class="register-tree__post-code-tile-statistics-bold align-items-baseline">
            <img :src="statistic.icon" />
            <span v-text="statistic.value"></span>
          </div>
          <p v-text="statistic.label"></p>
        </div>
      </div>
      <a :href="dto.submitButton.url" class="btn btn-outline-success button--large" v-text="dto.submitButton.text"></a>
      <small class="register-tree__post-code-tile-explanatory"
        v-text="dto.translations['registerTree.Form.PostCodeLeftTabExplanatoryText']"></small>
    </b-col>
    <b-col cols="12" lg="6" class="register-tree__post-code-tile register-tree__post-code-tile-right">
      <form @submit.prevent>
        <h2 class="register-tree__post-code-tile-header"
          v-text="dto.translations['registerTree.Form.PostCodeRightTabHeader']"></h2>
        <small v-text="dto.translations['registerTree.Form.PostCodeRightTabInfo']"></small>
        <b-input-group class="register-tree__post-code-submit">
          <b-form-input autofocus :placeholder="(dto.translations['registerTree.Form.PostCodeRightTabInputPlaceholder'])"
            v-model.trim="postCode.value" :state="postCode.isValid" v-on:keyup.enter="submit" name="postcode"></b-form-input>
          <b-input-group-append>
            <b-button :class="'register-tree__post-code-submit-button'" :disabled="postCode.found" variant="primary"
              @click="submit">
              <i class="fa fa-arrow-right" aria-hidden="true"></i>
            </b-button>
          </b-input-group-append>
        </b-input-group>
        <div v-if="postCode.isValid === false" class="text-danger"
          v-text="dto.translations['registerTree.Form.ErrorMessages.InvalidPostcode']"></div>
      </form>
    </b-col>
  </b-row>
</template>

<script>
import axios from "axios";
import { EventBus, CHRISTMAS_TREE_POST_CODE_SUBMITED } from "@/domain/events";
import collectionStatusMixin from "@/mixins/collectionStatusMixin";
import { isValidPostCode } from "@/domain/validationRules";

export default {
  name: "PostCodeForm",
  mixins: [collectionStatusMixin],
  components: {},

  data() {
    return {
      postCode: {
        value: "",
        isValid: undefined
      }
    };
  },

  props: {
    dto: Object
  },

  computed: {
    isCollectionOpen: function () {
      const from = new Date(this.dto.collectionStartDate);
      const to = new Date(this.dto.collectionEndDate);
      const now = new Date();

      return from <= now && now <= to;
    },
    standarizedPostcode() {
      return this.postCode.value.replace(/(\S*)\s*(\d)/, "$1 $2").toUpperCase();
    }
  },

  mounted() {
    var urlParams = new URLSearchParams(window.location.search);
    var postcodeFromHomePage = urlParams.get('inputValue');

    if (postcodeFromHomePage) {
      var myNewURL = this.removeParams();
      window.history.pushState("object or string", "Title", "/" + myNewURL)

      var inputField = document.querySelector('input[name="postcode"]');
      if (inputField) {
        inputField.value = postcodeFromHomePage;
        this.postCode.value = postcodeFromHomePage
        this.submit()
      }
    }
  },

  methods: {
    submit: function () {
      this.postCode.isValid = true;
      if (!isValidPostCode(this.postCode.value)) {
        this.postCode.isValid = false;
        return;
      }
      this.$setBuisy(true);
      const standarizedPostCode = this.standarizedPostcode;
      axios({
        method: "POST",
        url: this.dto.submitAction,
        data: {
          postCode: standarizedPostCode
        }
      })
        .then(result => {
          this.collectionLookupResult = result.data.collectionLookupResult;
          EventBus.$emit(CHRISTMAS_TREE_POST_CODE_SUBMITED, {
            postCode: standarizedPostCode,
            collection: result.data
          });
        })
        .catch(error => {
          throw error;
        })
    },

    removeParams: function() {
        var currURL= window.location.href;
        var afterDomain= currURL.split("/");
        var beforeQueryString= afterDomain[afterDomain.length - 2];  
    
        return beforeQueryString +'/';     
    }
  }
};
</script>
<style lang="scss">
@import "~@/components/TreeRegistration/RegisterTree.scss";
@import "~@/components/Payment.scss";
</style>
