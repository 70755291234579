<template>
  <b-form-group :label="model.translations['orderConfirmation.Contact.SectionTitle']">
    <p>
      {{state.email}}, {{state.phone}}
    </p>
  </b-form-group>
</template>
<script>

export default {
  name: "ContactInformation",
  data() {
    return {
      state: {
        email: this.model.email,
        phone: this.model.phone
      },
    };
  },
  props: {
    model: Object,
  }
};
</script>