<template>
	<div class="home">
		<carousel :model="dto.carousel" class="home--carousel"></carousel>
		<section class="home--statistics">
			<statistics-section
				:enableFreeTextArea="dto.freeTextAreaEnabled"
				:contentfreeTextArea="dto.freeTextAreaContent"
				:headerTitle="
					dto.heroStatistics.translations[
						'registerTree.Form.PostCodeLeftTabHeader'
					]
				"
				:headerText="
					dto.heroStatistics.isOpenCollection
						? dto.heroStatistics.translations[
								'registerTree.Form.PostCodeLeftTabCollectionOpenInfo'
						  ]
						: dto.heroStatistics.translations[
								'registerTree.Form.PostCodeLeftTabCollectionClosedInfo'
						  ]
				"
				:explanatoryText="
					dto.heroStatistics.translations[
						'registerTree.Form.PostCodeLeftTabExplanatoryText'
					]
				"
				:tiles="dto.statistics.statistics"
				:links="dto.heroStatistics.links"
			>
				<get-inspired :model="dto.getInspired"></get-inspired>
			</statistics-section>
		</section>
		<image-content-section
			:items="dto.newsAndStory"
			:firstImageOnLeft="false"
		></image-content-section>
		<news :dto="dto.news"></news>
		<div class="home__simple-statistics-section" v-if="!dto.freeTextAreaEnabled">
			<simple-statistics-section
				:model="dto.statistics"
				:shouldBeHidden="dto.freeTextAreaEnabled"
			></simple-statistics-section>
		</div>
	</div>
</template>

<script>
	import SimpleStatisticsSection from '../Common/SimpleStatisticsSection.vue';
	import Carousel from '../Common/Carousel';
	import StatisticsSection from '../Common/StatisticsSection.vue';
	import News from './News.vue';
	import ImageContentSection from '../Common/ImageContentSection';
	import GetInspired from '@/components/Common/GetInspired.vue';
	export default {
		name: 'Home',
		components: {
			SimpleStatisticsSection,
			Carousel,
			News,
			StatisticsSection,
			ImageContentSection,
			GetInspired,
		},
		data() {
			return {};
		},
		computed: {
			publicPath() {
				return this.$store.ConfigStore.getters.rootPath;
			},
		},
		props: {
			dto: Object,
		},
	};
</script>
<style lang="scss">
	@import '@/scss/_variables.scss';
	.home {
		&--statistics {
			background-color: $primary;
			padding-bottom: 1.5625rem;
		}
		&-section {
			text-align: center;
			font-weight: lighter;
			padding: 5.5rem 0 2.625rem;
			&__title {
				font-size: 2rem;
			}
			&__description {
				font-size: $font-size-sm;
			}
		}
		&__simple-statistics-section {
			top: 4.5rem;
			position: relative;
			.statistics-collection {
				padding-top: 4.6875rem;
				padding-bottom: 2.1875rem;
			}
		}
	}
	.statistics-section {
		&__tile {
			&__news {
				padding-top: 1.5625rem;
				text-align: left;
				img {
					width: 8.0625rem;
					height: auto;
					border: 0.25rem solid $lighten-gray;
				}

				.media {
					padding-bottom: 1rem;
				}

				h5 {
					font-size: 1.5rem;
					line-height: 2.0625rem;
					font-weight: $font-weight-bold;
					color: $primary;
				}
				p {
					font-size: 1rem;
					line-height: 1.75rem;
					font-weight: $font-weight-normal;
					color: $main-gray;
				}
			}
		}
	}
</style>
