import {
    BRow, BCol, BLink, BImg, BInputGroup, BFormInput, BInputGroupAppend,
    BButton, BContainer, BFormTextarea, BFormInvalidFeedback, BFormSelect,
    BFormSelectOption, BForm, BFormCheckboxGroup, BFormCheckbox, BFormGroup, BListGroupItem,
    ModalPlugin, CollapsePlugin 
  } from "bootstrap-vue"

export default {
    install: function (Vue) {
        Vue.use(ModalPlugin);
        Vue.use(CollapsePlugin);

        Vue.component('b-row', BRow);
        Vue.component('b-col', BCol);
        Vue.component('b-link', BLink);
        Vue.component('b-img', BImg);
        Vue.component('b-input-group', BInputGroup);
        Vue.component('b-form-input', BFormInput);
        Vue.component('b-input-group-append', BInputGroupAppend);
        Vue.component('b-button', BButton);
        Vue.component('b-container', BContainer);
        Vue.component('b-form-textarea', BFormTextarea);
        Vue.component('b-form-invalid-feedback', BFormInvalidFeedback);
        Vue.component('b-form-select', BFormSelect);
        Vue.component('b-form-select-option', BFormSelectOption);
        Vue.component('b-form', BForm);
        Vue.component('b-form-checkbox-group', BFormCheckboxGroup);
        Vue.component('b-form-checkbox', BFormCheckbox);
        Vue.component('b-form-group', BFormGroup);
        Vue.component('b-list-group-item', BListGroupItem);
    }
}