<template>
  <div class="get-inspired">
    <h2 class="statistics-section__tile--header" v-text="model.title"></h2>
    <small
      class="statistics-section__tile--text"
      v-text="model.description"
    ></small>
    <div class="statistics-section__tile__news">
      <ul class="list-unstyled">
        <b-media tag="li" :key="index" v-for="(item, index) in model.items">
          <template v-slot:aside v-if="item.image">
            <b-link
              v-if="item.link"
              :target="item.link.target"
              :href="item.link.url"
            >
              <b-img :src="item.image.url" class="mr-3"></b-img>
            </b-link>
            <b-link v-else href="#">
              <b-img :src="item.image.url" class="mr-3"></b-img>
            </b-link>
          </template>
          <template v-if="item.link">
            <b-link
              v-if="item.link"
              :target="item.link.target"
              :href="item.link.url"
            >
              <h5 class="mt-0 mb-1">{{ item.title }}</h5>
              <p class="mb-0">{{ item.description }}</p>
            </b-link>
            <b-link v-else href="#">
              <h5 class="mt-0 mb-1">{{ item.title }}</h5>
              <p class="mb-0">{{ item.description }}</p>
            </b-link>
          </template>
          <template v-else>
            <h5 class="mt-0 mb-1">{{ item.title }}</h5>
            <p class="mb-0">{{ item.description }}</p>
          </template>
        </b-media>
        <form @submit.prevent class="get-inspired__postcode-form">
          <h2
            class="register-tree__post-code-tile-header"
            v-text="
              model.translations['registerTree.Form.PostCodeRightTabHeader']
            "
          ></h2>
          <small
            v-text="
              model.translations['registerTree.Form.PostCodeRightTabInfo']
            "
          ></small>
          <b-input-group class="register-tree__post-code-submit">
            <b-form-input
              :placeholder="
                model.translations[
                  'registerTree.Form.PostCodeRightTabInputPlaceholder'
                ]
              "
              v-model.trim="postCode.value"
              :state="postCode.isValid"
              v-on:keyup.enter="submit"
            ></b-form-input>
            <b-input-group-append>
              <b-button
                :class="'register-tree__post-code-submit-button'"
                :disabled="postCode.found"
                variant="primary"
                @click="submit"
              >
                <i class="fa fa-arrow-right" aria-hidden="true"></i>
              </b-button>
            </b-input-group-append>
          </b-input-group>
          <div
            v-if="postCode.isValid === false"
            class="text-danger"
            v-text="
              model.translations[
                'registerTree.Form.ErrorMessages.InvalidPostcode'
              ]
            "
          ></div>
        </form>
      </ul>
    </div>
  </div>
</template>

<script>
import { BMedia } from "bootstrap-vue";
import { isValidPostCode } from "@/domain/validationRules";

export default {
  name: "GetInspired",
  props: ["model"],
  components: { BMedia },
  data() {
    return {
      postCode: {
        value: "",
        isValid: undefined,
      },
    };
  },
  computed: {
    standarizedPostcode() {
      return this.postCode.value.replace(/(\S*)\s*(\d)/, "$1 $2").toUpperCase();
    },
  },
  methods: {
    submit: function () {
      this.postCode.isValid = true;
      if (!isValidPostCode(this.postCode.value)) {
        this.postCode.isValid = false;
        return;
      }

      window.location.href = `/register-tree/?inputValue=${this.postCode.value}`;
    },
  },
};
</script>
<style>
.get-inspired__postcode-form {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
