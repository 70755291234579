<template>
  <section class="register-tree__no-collection padding--text-content--small">
    <b-modal
        ok-only
        id="register-tree__no-collection-thankyou-modal"
        :title="model.translations['registerTree.Form.NoCollectionInArea.ThankYouTitle']"
        @hide="redirect"
    >{{ model.translations['registerTree.Form.NoCollectionInArea.ThankYouText'] }}
    </b-modal>
    <b-form @submit.stop.prevent="submit" v-if="!state.isFormSubmited">
      <b-row align-h="center">
        <b-col cols="12">
          <b-row>
            <b-col cols="12">
              <div v-if="!fullRegistration" class="mb-3" v-html="model.headingHtml"></div>
              <div v-if="fullRegistration" class="mb-3" v-html="collectionFullText"></div>
            </b-col>
          </b-row>
          <hr class="mb-3">
          <b-row>
            <b-col cols="12">
              <h5 class="title--section color--pepper">
                {{ model.translations['registerTree.Form.NoCollectionInArea.HowToContact'] }}</h5>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <b-form-checkbox-group
                  name="contact-methods"
                  v-model="state.selectedContactMethods"
                  :options="model.contactMethods"
                  :state="validationState($v.state.selectedContactMethods)"
                  @change="onContactMethodChecked($event)"
                  stacked
              ></b-form-checkbox-group>
              <b-form-invalid-feedback
                  :state="validationState($v.state.selectedContactMethods)"
              >{{ model.translations['generic.SelectAtLeastOneOption'] }}
              </b-form-invalid-feedback>
            </b-col>
          </b-row>
          <hr class="mb-3" v-if="emailSelected || phoneOrTextSelected">
          <b-row v-if="emailSelected || phoneOrTextSelected">
            <b-col cols="12">
              <h5 class="title--section color--pepper">
                {{ 'Contact Details' }}</h5>
            </b-col>
          </b-row>
          <b-row v-if="emailSelected || phoneOrTextSelected">
            <b-col cols="12" lg="6" v-if="phoneOrTextSelected">
              <vue-tel-input name="phone" v-model="$v.state.phone.$model"
                             :placeholder="model.translations['generic.PersonalDetails.PhoneNumber']"
                             :wrapper-classes="{'is-invalid': validationState($v.state.phone) === false, 'custom-phone':true}"></vue-tel-input>

              <b-form-invalid-feedback
                  v-if="$v.state.phone.$dirty && !$v.state.phone.required"
              >{{ model.translations['generic.FieldRequired'] }}
              </b-form-invalid-feedback>
            </b-col>
            <b-col cols="12" lg="6" v-if="emailSelected">
              <b-form-input type="text"
                            :placeholder="model.translations['generic.PersonalDetails.Email']"
                            :state="validationState($v.state.email)"
                            v-model="state.email"></b-form-input>
              <b-form-invalid-feedback
                  v-if="!$v.state.email.required && $v.state.email.$dirty"
              >{{ model.translations['generic.FieldRequired'] }}
              </b-form-invalid-feedback>
              <b-form-invalid-feedback
                  v-if="!$v.state.email.email && $v.state.email.$dirty"
              >{{ model.translations['generic.FieldEmailInvalid'] }}
              </b-form-invalid-feedback>
            </b-col>
          </b-row>
          <hr class="mb-3">
          <b-row>
            <b-col cols="12">
              <h5 class="title--section color--pepper">
                {{ model.translations['registerTree.Form.NoCollectionInArea.AdditionalContactInfo'] }}</h5>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
               <textarea
                   placeholder="Other details"
                   wrap="soft"
                   class="form-control"
                   v-model="state.additionalInfo"
               ></textarea>
            </b-col>
          </b-row>
          <hr class="mb-3">
          <b-row>
            <b-col cols="12">
              <h5 class="title--section color--pepper">
                {{ model.translations['registerTree.Form.NoCollectionInArea.LeadSource'] }}</h5>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <b-form-select v-model="state.selectedLeadSource" :options="leadSources"></b-form-select>
            </b-col>
          </b-row>
          <hr class="mb-3" v-if="hospices && hospices.length">
          <b-row>
            <b-col cols="12" v-if="hospices && hospices.length">
              <h5 class="title--section color--pepper">
                {{ model.translations['registerTree.Form.NoCollectionInArea.WhoCollects'] }}</h5>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" v-if="hospices && hospices.length">
              <b-list-group horizontal>
                <b-list-group-item v-for="(hospice,$index) in hospices" :key="$index">
                  <a :href="hospice.url" v-text="hospice.name" target="_blank"></a>
                </b-list-group-item>
              </b-list-group>
            </b-col>
          </b-row>
          <b-row align-h="end">
            <b-col cols="12" lg="6" class="text-center">
              <b-button
                  type="submit"
                  variant="primary"
                  class="button--large"
              >{{ model.translations['registerTree.Form.NoCollectionInArea.SubmitButtonText'] }}
              </b-button>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-form>
    <b-alert
        v-model="state.isError"
        class="position-fixed fixed-top m-0 rounded-0"
        style="z-index: 2000; text-align: center;"
        variant="danger"
        dismissible
    >
      {{ model.translations['generic.Error'] }}
    </b-alert>
  </section>
</template>

<script>
import axios from "axios";
import ValidationMixin from "@/mixins/validationMixin";
import {contactMethod} from "@/domain/constants";
import {scrollOptions} from "@/domain/utils";
import {required, email} from "vuelidate/lib/validators";
import {load} from "recaptcha-v3";
import {BAlert, BListGroup} from "bootstrap-vue";

export default {
  name: "RegistrationCloseForm",
  mixins: [ValidationMixin],
  components: {BAlert, BListGroup},
  mounted() {
    this.$scrollTo(this.$el, 200, scrollOptions);

    load(this.model.reCaptcha.siteKey).then(recaptcha => {
      this.recaptcha = recaptcha;
      this.$setBuisy(false);
    });
  },
  data() {
    return {
      recaptcha: Object,
      state: {
        selectedContactMethods: [],
        selectedLeadSource: "",
        additionalInfo: "",
        email: "",
        phone: "",
        isFormSubmited: false,
        isError: false
      }
    };
  },
  validations() {
    let rule = {
      state: {
        selectedContactMethods: {
          required
        }
      }
    };
    if (this.emailSelected) {
      rule.state.email = {
        required,
        email
      };
    }
    if (this.phoneOrTextSelected) {
      rule.state.phone = {
        required
      };
    }
    return rule;
  },
  props: {
    model: Object,
    postCode: String,
    hospices: Array,
    fullRegistration: Boolean
  },
  computed: {
    emailSelected() {
      return this.state.selectedContactMethods.includes(contactMethod.email);
    },
    phoneOrTextSelected() {
      return (
          this.state.selectedContactMethods.includes(contactMethod.text) ||
          this.state.selectedContactMethods.includes(contactMethod.phone)
      );
    },
    leadSources() {
      return [{text: this.model.translations['generic.Select'], value: "", disabled: true}, ...this.model.leadSources]
    },
    collectionFullText(){
      return this.model.collectionFullHeadingHtml.replace("{collection}", this.hospices[0].name);
    }
  },
  methods: {
    onContactMethodChecked(event) {
      let isNoContactSelected = event.includes(contactMethod.noContact);
      if (isNoContactSelected && event.length > 1) {
        let noContactIndex = event.indexOf(contactMethod.noContact);

        if (noContactIndex != 0) {
          this.state.selectedContactMethods = [contactMethod.noContact];
        } else {
          event.splice(noContactIndex, 1);
        }
      }

    },
    scrollToFirstInvalidElement() {
      let element = this.$el.getElementsByClassName("is-invalid")[0];
      if (element != undefined) {
        this.$scrollTo(element, 200, {offset: -80});
      }
    },
    submit: function () {
      this.$v.$touch();
      if (this.$v.$anyError) {
        this.$nextTick(() => this.scrollToFirstInvalidElement());
        return;
      }
      this.$setBuisy(true);
      this.recaptcha.execute("register").then(token => {
        this.state.isError = false;
        axios({
          method: "POST",
          url: this.model.submitUrl,
          data: {
            contactMethods: this.state.selectedContactMethods.join(","),
            additionalInformation: this.state.additionalInfo,
            LeadSource: this.state.selectedLeadSource,
            email: this.state.email,
            phone: this.state.phone,
            postCode: this.postCode,
            captchaToken: token
          }
        })
            .then(() => {
              this.state.isFormSubmited = true;
              this.$bvModal.show("register-tree__no-collection-thankyou-modal");
            })
            .catch(() => {
              this.state.isError = true;
              this.state.isFormSubmited = false;
              this.$setBuisy(false);
            });
      });
    },
    redirect() {
      window.location.href = "/";
    }
  }
};
</script>
<style lang="scss">
//@import "./RegisterTree";
@import "~@/scss/_variables.scss";
@import "~@/scss/_breakpoints.scss";

.register-tree {
  &__no-collection {
    .row {
      @include media-breakpoint-up(lg) {
        padding-bottom: 1rem;
      }
    }

    &-thankyou {
      margin-top: 0.625rem;
      color: $primary;
    }

    // button {
    //   width: 10.625rem;
    //   margin-top: 3.125rem;
    // }
    // select,
    // input {
    //   width: 18.4375rem;
    //   display: block;
    // }
  }
}
</style>
