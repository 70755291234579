<template>
  <div class="article">
    <base-page :model="heroModel" box-shadow>
      <div class="article-details color--basic">
        <b-img
          class="article-details__image image--cover"
          :src="model.image.url"
          :alt="model.image.text"
        ></b-img>
        <div class="article-details__content padding--section padding--content">
          <p class="title--main font--bold color--basic">{{model.title}}</p>
          <span
            class="article-details__content--explanatory color--explanatory font--light"
          >{{longDate}}</span>
        </div>
        <slot></slot>
        <social-sharing inline-template :icondescription="model.translations['article.Share']">
          <div class="article-details__content-social-share">
            <div class="social-share-icon">
              <network network="facebook">
                <i class="fa fa-facebook"></i>
              </network>
              <small v-text="$attrs.icondescription"></small>
            </div>
            <div class="social-share-icon">
              <network network="twitter">
                <i class="fa fa-twitter"></i>
              </network>
              <small v-text="$attrs.icondescription"></small>
            </div>
            <div class="social-share-icon">
              <network network="linkedin">
                <i class="fa fa-linkedin"></i>
              </network>
              <small v-text="$attrs.icondescription"></small>
            </div>
          </div>
        </social-sharing>
        <div class="article-details__tags padding--section padding--content">
          <span class="btn btn-success" :key="index" v-for="(item, index) in model.tags">{{item}}</span>
        </div>
      </div>
    </base-page>
    <related-articles v-if="model.relatedArticles && model.relatedArticles.length" :model="relatedArticles"></related-articles>
  </div>
</template>
<script>
import BasePage from "../Common/BasePage";
import RelatedArticles from "./RelatedArticles";
import { longLocalDate } from "@/domain/utils";
export default {
  name: "ArticlePage",
  components: {
    BasePage,
    RelatedArticles
  },
  props: {
    model: Object
  },
  computed: {
    longDate() {
      return longLocalDate(this.$store.ConfigStore.getters.culture, this.model.date);
    },
    heroModel() {
      return {
        title: this.model.title,
        subtitle: this.longDate
      };
    },
    relatedArticles() {
      return {
        articles: this.model.relatedArticles,
        translations: this.model.translations
      };
    }
  }
};
</script>
<style lang="scss">
@import "@/scss/_variables";
.article {
  .base-page__details--full {
    padding: 0;
  }
  &-details {
    &__content {
      &--explanatory {
        font-size: 0.8125rem;
      }
      &-social-share {
        color: $primary;
        font-size: 2.8125rem;
        text-align: center;
        .social-share-icon {
          width: 5rem;
          display: inline-block;
        }
        span {
          padding: 0.625rem 0.875rem;
          border-radius: 5.25rem;
          display: inline;
          &:hover {
            background-color: $light-gray;
            cursor: pointer;
            ~ small {
              display: block;
            }
          }
          i {
            width: 3.125rem;
          }
        }
        small {
          display: none;
          margin-top: 0.3125rem;
          font-size: $font-size-sm;
          text-transform: uppercase;
          font-weight: $font-weight-semibold;
          position: absolute;
          width: 5rem;
        }
      }
    }
    &__body {
      p {
        padding-bottom: 1.5rem;
      }
      .row {
        padding-bottom: 1.5rem;
        p:last-child {
          padding-bottom: 0;
        }
      }
      .video-wrapper{
        text-align: center;
      }
    }
    &__tags {
      padding-bottom: 5.3125rem;
      span {
        font-size: 0.75rem;
        margin-right: 0.625rem;
        margin-bottom:0.5rem;
      }
    }
  }
}
</style>