<template>
  <b-form-group :label="model.translations['orderConfirmation.AdditionalInformation.SectionTitle']">
    <p>
      {{state.otherDetails}}
    </p>
  </b-form-group>
</template>
<script>

export default {
  name: "AdditionalInformation",
  data() {
    return {
      state: {
        otherDetails: this.model.otherDetails
      },
    };
  },
  props: {
    model: Object,
  }
};
</script>