<template>
  <b-form-group :label="model.translations['orderConfirmation.AddressInformation.SectionTitle']">
    <p>
      {{state.addressLine1}} {{state.addressLine2}} {{state.addressLine3}}
      <br />
      {{state.city}} {{state.province}}, {{model.postcode}}
    </p>
  </b-form-group>
</template>
<script>

export default {
  name: "AddressInformation",
  data() {
    return {
      state: {
        addressLine1: this.model.addressLine1,
        addressLine2: this.model.addressLine2,
        addressLine3: this.model.addressLine3,
        city: this.model.city,
        province: this.model.province
      },
    };
  },
  props: {
    model: Object,
  }
};
</script>