<template>
  <div class="related-articles container-fluid pl-0 pr-0">
    <h3 class="container" v-text="model.translations['article.RelatedArticle.SectionTitle']"></h3>
    <div class="related-articles__list" v-dragscroll>
      <single-related-article
        v-for="article in model.articles"
        :key="article.link"
        :model="article"
        :date-prefix="model.translations['article.RelatedArticle.DatePrefix']"
      ></single-related-article>
    </div>
  </div>
</template>

<script>
import SingleRelatedArticle from "./SingleRelatedArticle";
import { dragscroll } from "vue-dragscroll";

export default {
  name: "RelatedArticles",
  components: { SingleRelatedArticle },
  directives: {
    dragscroll: dragscroll
  },
  props: {
    model: Object
  }
};
</script>
<style lang="scss">
@import "@/scss/_variables";
.related-articles {
  background: linear-gradient(180deg, $primary 50%, $white 50%);
  h3 {
    color: $white;
    padding-top: 130px;
    font-size: 2.125rem;
    font-weight: bold;
  }
  &__list {
    text-align: center;
    padding: 50px 0 130px;
    white-space: nowrap;
    overflow-x: hidden;
    > * {
      position: relative;
      cursor: -webkit-grab;
      cursor: -moz-grab;
      cursor: -o-grab;
      cursor: grab;
      :active {
        cursor: -webkit-grabbing;
        cursor: -moz-grabbing;
        cursor: -o-grabbing;
        cursor: grabbing;
      }
    }
  }
}
</style>