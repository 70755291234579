<template>
  <div class="text-content-section color--basic">
    <div :class="getRowStyle(index)" :key="index" v-for="(item, index) in items">
      <b-container>
        <b-row>
          <b-col>
            <div class="text-content-container">
              <h5 class="text-content-container__title">{{item.title}}</h5>
              <div class="text-content-container__description" v-html="item.body"></div>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>
<script>
export default {
  name: "TextContentSection",
  props: {
    items: Array
  },
  computed: {},
  methods: {
    getRowStyle: function(index) {
      return this.getValue(
        index,
        "text-content-section--even",
        "text-content-section--odd"
      );
    },
    getValue: function(index, firstValue, secondValue) {
      if (index % 2 === 1) return secondValue;
      return firstValue;
    }
  }
};
</script>
<style lang="scss">
@import "~@/scss/_variables.scss";
@import "~@/scss/_breakpoints.scss";
.text-content-section {
  &--even {
    background-color: $white;
  }
  &--odd {
    background-color: $light-gray;
    h5 {
      color: $primary;
    }
  }
  .text-content-container {
    padding: 5.3125rem 5rem;
    @include media-breakpoint-down(lg) {
      padding: 5.3125rem 2.5rem;
    }
    &__title {
      font-size: 2.0625rem;
      margin-bottom: 10px;
      font-weight: $font-weight-bold;
    }
    &__description {
      font-size: 1.125rem;
      line-height: 2.0625rem;
      p {
        margin-top: 30px;
      }
      a,
      a:hover {
        color: $primary;
      }
      ul {
        list-style: none;
        margin-top: 30px;
        li::before {
          content: "\2022";
          color: $primary;
          font-weight: bold;
          display: inline-block;
          width: 20px;
          margin-left: -20px;
          font-size: 1.5rem;
        }
      }
    }
  }
}
</style>