<template>
  <div class="content-page">
    <div class="content-page-header hero-section hero-section-header">
      <b-row align-h="start" class="justify-content-md-center">
        <b-col>
          <b-container class="hero-section">
            <span v-if="model.subtitle" class="hero-section__subtitle" v-text="model.subtitle"></span>
            <h2 v-if="model.title" class="hero-section__title" v-text="model.title"></h2>
            <p
              v-if="model.description"
              class="hero-section__description"
              v-text="model.description"
            ></p>
            <div v-if="model.links && model.links.length" class="hero-section__buttons">
              <div v-for="(link,$index) in model.links" :key="$index">
                <b-button :href="link.url" variant="outline-secondary" class="button--large">{{link.text}}</b-button>
              </div>
            </div>
          </b-container>
        </b-col>
      </b-row>
    </div>
    <div class="content-page-details hero-section-details">
      <slot v-if="fullWidth"></slot>
      <b-container v-if="!fullWidth">
        <b-row class="carousel-overlayer">
          <section class="shadow-box data-collection">
            <slot></slot>
          </section>
        </b-row>
      </b-container>
    </div>
  </div>
</template>
<script>
export default {
  name: "ContentPage",
  props: {
    model: Object,
    fullWidth: {
      type: Boolean,
      default: false
    }
  }
};
</script>
<style lang="scss">
@import "@/scss/_variables.scss";
.content-page {
  &-details {
    .data-collection {
      margin: 0;
    }
  }
  &-header {
    padding: 6.25rem 0;
    background-color: $primary;
  }
}
</style>