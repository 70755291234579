<template>
    <transition name="page-alert-fade">
        <div v-if="model.enabled && !hide" class="page-alert alert" :class="alertType" role="alert">
            <button type="button" class="close" data-dismiss="alert" aria-label="Close" @click="hideAlert">
                <span aria-hidden="true">&times;</span>
            </button>
            <h4 class="alert-heading" v-text="model.title"></h4>
            <p class="alert-body" v-html="model.description"></p>
        </div>
    </transition>
</template>

<script>
    export default {
        name: "SiteAlert",
        props: {
            model: Object
        },
        data() {
            return {
                hide: true,
            }
        },
        mounted() {
            this.hide = sessionStorage.hideAlert === "true";
        },
        computed: {
            alertType() {
                if (this.model.type)
                    return "alert-" + this.model.type;
                return 'alert-primary';
            }
        },
        methods: {
            hideAlert() {
                this.hide = true;
                sessionStorage.hideAlert = "true";
            }
        }
    }
</script>

<style lang="scss">
    .page-alert {
        margin-bottom: 0;
        a, a:hover{
            color: #6f1215;
            font-weight: bold;
            text-decoration: underline;
        }
    }

    .page-alert-fade-enter-active, .page-alert-fade-leave-active {
        transition: opacity .5s;
    }

    .page-alert-fade-enter, .page-alert-fade-leave-to {
        opacity: 0;
    }
</style>