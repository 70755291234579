const dateOptionsLong = {
    hour12: false,
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
};
const dateTimeOptionsShort = {
    hour12: true,
    month:"short",
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric'
};
const dateOnlyOptionsShort = {
    year: 'numeric',
    month:"short",
    day: 'numeric',
};
const scrollOptions = {
    easing: "ease-in",
    force: true,
    cancelable: false,
    x: false,
    y: true
};


const longLocalDate = (culture, date) => {
    var parsedDate = Date.parse(date);
    if (isNaN(parsedDate))
        return "";
    return new Date(date).toLocaleDateString(culture, dateOptionsLong);
}

const shortLocalDateTime = (culture, date) => {
    var parsedDate = Date.parse(date);
    if (isNaN(parsedDate))
        return "";
    return new Date(date).toLocaleDateString(culture, dateTimeOptionsShort).replace("am","AM").replace("pm","PM");
}
const shortLocalDate = (culture, date) => {
    var parsedDate = Date.parse(date);
    if (isNaN(parsedDate))
        return "";
    return new Date(date).toLocaleDateString(culture, dateOnlyOptionsShort);
}

export { scrollOptions, longLocalDate, shortLocalDateTime ,shortLocalDate}