export const contactMethod = {
	noContact: 'Do not contact',
	phone: 'Phone',
	email: 'Email',
	text: 'Text',
};
export const registrationSteps = {
	hospice: 'hospice',
	location: 'location',
	contactDetails: 'contactDetails',
	itemsToCollect: 'itemsToCollect',
	donations: 'donations',
	keepInTouch: 'keepInTouch',
	summary: 'summary',
};
export const nameFieldMaxLength = 35;
