<template>
  <ul class="hospice-contact list-unstyled">
    <b-media v-if="model.address" tag="li" vertical-align="center">
      <template v-slot:aside>
        <b-img :src="`${publicPath}/icons/pointer.png`" width="32" height="32"></b-img>
      </template>
      <span>{{model.address}}</span>
    </b-media>

    <b-media v-if="model.website" tag="li" vertical-align="center">
      <template v-slot:aside>
        <b-img :src="`${publicPath}/icons/tree.png`" width="32" height="32"></b-img>
      </template>
      <span v-if="model.collectionDateEnd">{{model.translations['hospice.Contact.CollectionOpenAndCloseDate']}} {{toLocalDate(model.collectionDateStart)}} - {{toLocalDate(model.collectionDateEnd)}}</span>
      <span v-else>{{model.translations['hospice.Contact.CollectionOnlyOpenDate']}} {{toLocalDate(model.collectionDateStart)}}</span>
    </b-media>
    <b-media v-if="model.website" tag="li" vertical-align="center">
      <template v-slot:aside>
        <b-img :src="`${publicPath}/icons/link.png`" width="32" height="32"></b-img>
      </template>
      <b-link :href="model.website.url" target="_blank" class="default-link">{{model.website.text}}</b-link>
    </b-media>
  </ul>
</template>
<script>
import { BMedia } from "bootstrap-vue";
import { longLocalDate } from "@/domain/utils";
export default {
  name: "HospiceContact",
  components: {
    BMedia
  },
  props: {
    model: Object,
  },
   computed: {
    publicPath() {
      return this.$store.ConfigStore.getters.rootPath;
    }
  },
  methods: {
    toLocalDate(date) {
      return longLocalDate(this.$store.ConfigStore.getters.culture, date);
    }
  }
};
</script>
<style lang="scss">
@import "@/scss/_variables.scss";
</style>