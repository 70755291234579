<template>
  <div class="image-content-list">
    <b-row
        class="image-content-container"
        :class="getRowStyle(index)"
        :key="index"
        v-for="(item, index) in items"
    >
      <b-col
          class="image-content-container__image pr-0 pl-0"
          cols="12"
          md="6"
          :order-md="getOrder(index)"
          :style="getBackgroundImageStyle(item.image.url)"
      ></b-col>
      <b-col class="pr-0 pl-0" cols="12" md="6" align-self="center" :class="{'wide-description':wideDescription}">
        <b-container>
          <b-row>
            <b-col sm="12" md="10" offset-md="1" :lg="wideDescription?7:6" :offset-lg="getOffset(index)">
              <div class="image-content-container__description">
                <div class="image-content-container__description__text" v-html="item.text"></div>
                <div v-if="item.buttonLink != null" class="image-content-item__button">
                  <b-button
                      variant="success"
                      class="button--large"
                      :href="item.buttonLink.url"
                  >{{ item.buttonLink.text }}
                  </b-button>
                </div>
                <div
                    v-if="item.explanationText != null"
                    class="image-content-item__explanation-text"
                >{{ item.explanationText }}
                </div>
              </div>
            </b-col>
          </b-row>
        </b-container>
      </b-col>
    </b-row>
  </div>
</template>
<script>
export default {
  name: "ImageContentSection",
  props: {
    items: Array,
    firstImageOnLeft: Boolean,
    wideDescription: {
      type: Boolean,
      default: false,
    }
  },
  computed: {},
  methods: {
    getBackgroundImageStyle: function (url) {
      return {
        "background-image": `url(${url})`,
        "background-repeat": "no-repeat",
        "background-size": "cover"
      };
    },
    getOffset: function (index) {
      return this.getValue(index, 1, 5);
    },
    getOrder: function (index) {
      return this.getValue(index, 0, 1);
    },
    getPadding: function (index) {
      return this.getValue(index, "pdl-100", "pdr-100");
    },
    getRowStyle: function (index) {
      return this.getValue(
          index,
          "image-content-container--even",
          "image-content-container--odd"
      );
    },
    getValue: function (index, firstValue, secondValue) {
      if (this.firstImageOnLeft) {
        if (index % 2 === 1) return secondValue;
        return firstValue;
      }
      if (index % 2 === 1) return firstValue;
      return secondValue;
    }
  }
};
</script>
<style lang="scss">
@import "~@/scss/_variables.scss";
@import "~@/scss/_breakpoints.scss";

.image-content {
  &-list {
    .row {
      margin-left: 0;
      margin-right: 0;
    }

  }

  &-container {
    & > .wide-description {
      margin-top: 1.625rem;
      margin-bottom: auto;
    }

    &__image {
      @include media-breakpoint-down(sm) {
        height: 366px;
      }
      @include media-breakpoint-up(md) {
        height: 621px;
      }
    }

    &__description {

      @include media-breakpoint-down(lg) {
        padding: 2.5rem 0;
      }
    }

    &--even {
      background-color: $primary;

      .image-content-container__description__text {
        a {
          color: $white;
        }
      }

      .btn-success {
        background-color: $white;
        color: $primary !important;
      }

      .image-content-item {
        &--title {
          color: $white;
        }

        &--subtitle {
          color: $white;
        }

        &--description {
          color: $white;
        }
      }
    }

    &--odd {
      background-color: $white;

      a {
        color: $white;
      }

      .image-content-container__description__text {
        a {
          color: $primary;
        }
      }

      .image-content-item {
        &--title {
          color: $primary;
        }

        &--subtitle {
          color: $main-gray;
        }

        &--description {
          color: $main-gray;
        }
      }
    }

    &__image {
      @include media-breakpoint-up(lg) {
        max-height: 621px;
      }
    }
  }
}
</style>