<template>
  <div class="article-list padding--section">
    <h4 class="title--main">{{title}}</h4>
    <article-list-item :key="index" v-for="(item, index) in items" :model="item" :button-label="readMore"></article-list-item>
    <div v-if="loadMore" class="article-list__button">
      <b-link class="btn btn-success button--large" :href="loadMore.url">{{loadMore.text}}</b-link>
    </div>
  </div>
</template>
<script>
import ArticleListItem from "./ArticleListItem";

export default {
  name: "ArticleList",
  components: { ArticleListItem },
  props: {
    items: Array,
    title: String,
    loadMore: Object,
    readMore: String
  },
  computed: {},
  methods: {}
};
</script>
<style lang="scss">
.article-list {
  h4 {
    padding-bottom: 0.75rem;
  }
  &__button {
    text-align: center;
    padding-top: 5.3125rem;
  }
}
</style>