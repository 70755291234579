<template>
  <b-nav-text v-if="item.type === 'Button'">
    <b-button variant="success" :href="url">{{ item.text }}</b-button>
  </b-nav-text>
  <b-nav-text v-else-if="item.type === 'Outline button'">
    <b-button variant="outline-success" :href="url">{{ item.text }}</b-button>
  </b-nav-text>
  <b-nav-item
      v-else-if="item.type === 'Link'"
      :target="item.link ? item.link.target : '_self'"
      :href="url"
      :class="[isActive ? 'current' : '', 'text-center']"
  >{{ item.text }}
  </b-nav-item>
  <b-nav-item-dropdown :text="item.text" right
                       :class="'text-center'"
                       v-else-if="item.type === 'Dropdown'">
    <b-dropdown-item
        :href="navItem.url"
        v-bind:key="index"
        :target="navItem.link ? navItem.link.target : '_self'"
        v-for="(navItem, index) in item.subNavigationElements">{{ navItem.text }}
    </b-dropdown-item>
  </b-nav-item-dropdown>
  <b-nav-text v-else>{{ item.text }}</b-nav-text>
</template>
<script>
import {BNavItem, BNavText, BNavItemDropdown, BDropdownItem} from "bootstrap-vue";

export default {
  name: "NavigationElement",
  components: {BNavItem, BNavText, BNavItemDropdown, BDropdownItem},
  props: {
    item: Object
  },
  computed: {
    url: function () {
      if (this.item.link) {
        return this.item.link.url;
      }
      return "#";
    },
    isActive: function () {
      return this.url == window.location.pathname;
    }
  }
};
</script>
<style lang="scss">
@import "~@/scss/_variables.scss";
@import "~@/scss/_breakpoints.scss";

.navigation-bar {
  color: $main-gray;

  &__links {
    &-top {
      &--item {
        padding: 0.6875rem 0rem 0.5rem 0.9375rem;

        a {
          font-size: 0.75rem;
          padding: 0.375rem 1.125rem;
          font-weight: 600 !important;
          min-width: 6.25rem;
        }
      }
    }

    &-bottom {

      &--item {
        padding-left: 1.5625rem;
        @include media-breakpoint-down(md) {
          padding-right: 1.5625rem;
        }

        .nav-link {
          padding-right: 0 !important;
          padding-left: 0 !important;
          font-weight: 600 !important;
        }

        .dropdown-menu {
          border: 1px $lighten-gray solid;
          .dropdown-item {
            font-weight: 600 !important;
            color: rgba(0, 0, 0, 0.5);
          }

          .dropdown-item:hover {
            color: rgba(0, 0, 0, 0.5);
            background-color: inherit;
          }
        }

        a {
          box-shadow: 0 0 0 0 $primary;
          transition: 0.15s;
        }

        a:hover {
          box-shadow: 0 0.25rem 0 0 $primary;
        }
      }
    }
  }
}
</style>