<template>
    <div>
        <base-page box-shadow v-if="model.invalidOrder" :model="contentPageModel">
            <p
                    class="data-collection__title"
                    v-text="model.translations['orderConfirmation.InvalidOrder']"
            ></p>
        </base-page>
        <base-page box-shadow v-if="model.isDonation && !model.invalidOrder" :model="contentPageModel">
            <div class="order-confirmation-details__edit-area">
                <b-form-group
                        :label="model.translations['orderConfirmation.Order.SectionTitle']"
                        class="order-confirmation-details__edit-area__order-number"
                >
                    <b-form-input
                            class="data-collection-location__form-postcode"
                            :value="model.orderNumber"
                            disabled
                    ></b-form-input>
                    <span>{{model.translations['orderConfirmation.Order.Description']}}</span>
                </b-form-group>
                <b-form-group v-if="model.transactionId"
                              :label="model.translations['donation.Confirmation.TransactionId']">
                    <p>{{model.transactionId}}</p>
                </b-form-group>
                <b-form-group :label="model.translations['donation.Confirmation.OrderSummary']">
                    <p>{{model.amountSummary}}</p>
                </b-form-group>
                <b-form-group v-if="model.giftAidSummary"
                              :label="model.translations['donation.Confirmation.GiftAidSummary']">
                    <p>{{model.giftAidSummary}}</p>
                </b-form-group>
                <personal-details :model="model.personalDetails"></personal-details>
                <contact-information :model="model.contactInformation"></contact-information>
                <address-information :model="model.addressInformation"></address-information>
                <additional-information :model="model.additionalInformation"
                                        v-if="model.additionalInformation.otherDetails"></additional-information>
            </div>
        </base-page>
        <base-page box-shadow v-if="!model.isDonation && !model.invalidOrder" :model="contentPageModel">
            <h2 v-text="model.translations['orderConfirmation.SectionTitle']"></h2>
            <p
                    class="data-collection__title"
                    v-text="model.translations['orderConfirmation.SectionDescription']"
            ></p>
            <div class="order-confirmation-details__edit-area">
                <b-form-group
                        :label="model.translations['orderConfirmation.Order.SectionTitle']"
                        class="order-confirmation-details__edit-area__order-number"
                >
                    <b-form-input
                            class="data-collection-location__form-postcode"
                            :value="model.orderNumber"
                            disabled
                    ></b-form-input>
                    <span>{{model.translations['orderConfirmation.Order.Description']}}</span>
                </b-form-group>
                <b-form-group v-if="model.amountSummary"
                              :label="model.translations['orderConfirmation.Email.DonationLabel']">
                    <p v-text="model.amountSummary"></p>
                </b-form-group>
                <personal-details :model="model.personalDetails"></personal-details>
                <contact-information :model="model.contactInformation"></contact-information>
                <address-information :model="model.addressInformation"></address-information>
                <collected-items :model="model.collectedItems"></collected-items>
                <additional-information :model="model.additionalInformation"></additional-information>
                <social-sharing :title="model.socialShare.title"
                                :description="model.socialShare.description"
                                :quote="model.socialShare.description"
                                :url="absoluteShareUrl" inline-template
                                :media="model.socialShare.image"
                                :icondescription="model.translations['article.Share']">
                    <div class="article-details__content-social-share">
                        <div class="social-share-icon">
                            <network network="facebook">
                                <i class="fa fa-facebook"></i>
                            </network>
                            <small v-text="$attrs.icondescription"></small>
                        </div>
                        <div class="social-share-icon">
                            <network network="twitter">
                                <i class="fa fa-twitter"></i>
                            </network>
                            <small v-text="$attrs.icondescription"></small>
                        </div>
                        <div class="social-share-icon">
                            <network network="linkedin">
                                <i class="fa fa-linkedin"></i>
                            </network>
                            <small v-text="$attrs.icondescription"></small>
                        </div>
                    </div>
                </social-sharing>
            </div>
        </base-page>
    </div>
</template>
<script>
    import BasePage from "../Common/BasePage";
    import PersonalDetails from "./Sections/PersonalDetails";
    import ContactInformation from "./Sections/ContactInformation";
    import AdditionalInformation from "./Sections/AdditionalInformation";
    import CollectedItems from "./Sections/CollectedItems";
    import AddressInformation from "./Sections/AddressInformation";

    export default {
        name: "OrderConfirmation",
        components: {
            BasePage,
            PersonalDetails,
            ContactInformation,
            AdditionalInformation,
            CollectedItems,
            AddressInformation
        },
        props: {
            model: Object
        },
        computed: {
            contentPageModel() {
                let pageModel = Object;
                pageModel.title = this.model.title;
                if (this.model.isDonation)
                    pageModel.description = this.model.translations['orderConfirmation.SectionDonationDescription'];

                if (this.model.hospiceInfo) {
                    pageModel.image = {url: this.model.hospiceInfo.logoUrl};
                    pageModel.links = [{
                        url: this.model.hospiceInfo.url,
                        target: '_blank',
                        text: this.model.translations['hospiceList.ViewHospiceDetailPageButtonText']
                    }]
                }
                return pageModel;
            },
            absoluteShareUrl() {
                if (!this.model.socialShare || !this.model.socialShare.url || !this.model.socialShare.url.url) {
                    return window.location.href
                }
                if (/^https?:\/\//i.test(this.model.socialShare.url.url)) {
                    return this.model.socialShare.url.url;
                }
                return `${window.location.origin}${this.model.socialShare.url.url}`
            }
        }
    };
</script>
<style lang="scss">
    @import "~@/scss/_variables.scss";
    @import "~@/scss/_breakpoints.scss";

    .order-confirmation {
        &-details {
            &__edit-area {
                .data-collection__checkbox {
                    padding-top: 10px;
                }

                .edit {
                    &-label {
                        font-size: 8px;
                        color: $lighten-gray;
                        margin: 0;
                    }

                    &-link {
                        margin-left: 20px;
                        font-size: 13px;
                        color: $link-color;
                        text-decoration: underline;
                        text-transform: lowercase;
                    }

                    &-link:hover {
                        color: $link-color;
                        text-decoration: underline;
                        cursor: pointer;
                    }

                    &-button {
                        width: 22px;
                        border-radius: 100%;
                        text-align: center;
                        display: inline-block;
                        color: $lighten-gray;
                        margin-right: 10px;
                        cursor: pointer;
                        background: initial;
                        border: initial;
                        padding: 0;

                        &--accept:hover {
                            color: $primary;
                        }

                        &--decline:hover {
                            color: red;
                        }
                    }

                    &-button:hover {
                        box-shadow: 0px 0.1875rem 0.375rem rgba(0, 0, 0, 0.24);
                    }
                }

                legend {
                    font-size: 13px;
                    line-height: 32px;
                    color: $lighten-gray;
                    padding-bottom: 0;
                }

                .form-group {
                    margin-bottom: 30px;

                    p {
                        font-weight: $font-weight-bold;
                        color: $main-gray;
                        line-height: 24px;
                        padding-left: 14px;
                        margin-top: -5px;
                    }
                }

                &__order-number {
                    input {
                        display: inline-block;
                        width: auto;
                        @include media-breakpoint-down(md) {
                            width: 100%;
                        }
                    }

                    span {
                        display: inline-block;
                        font-weight: $font-weight-light;
                        line-height: 28px;
                        color: $main-gray;
                        margin-left: 16px;
                    }
                }
            }
        }
    }
</style>