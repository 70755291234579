import Vue from "vue"
import Vuex from "vuex";
import { registrationSteps } from "@/domain/constants"

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        stepFlow: {
            [registrationSteps.hospice]: registrationSteps.location,
            [registrationSteps.location]: registrationSteps.contactDetails,
            [registrationSteps.contactDetails]: registrationSteps.itemsToCollect,
            [registrationSteps.itemsToCollect]: registrationSteps.donations,
            [registrationSteps.donations]: registrationSteps.keepInTouch,
            [registrationSteps.keepInTouch]: registrationSteps.summary,
            [registrationSteps.summary]: null
        },
        currentStep: registrationSteps.hospice,
        visitedSteps: [],
        hospice: Object,
        location: Object,
        contactDetails: Object,
        itemsToCollect: Object,
        donations: Object,
        keepInTouch: Object,
        summary: Object
    },
    getters: {
        currentStep(state) {
            return state.currentStep;
        },
        currentStepId(state){
            if (state.currentStep == registrationSteps.location) {
                return state.location.id;
            }
            if (state.currentStep == registrationSteps.contactDetails) {
                return state.contactDetails.id;
            }
            if (state.currentStep == registrationSteps.itemsToCollect) {
                return state.itemsToCollect.id;
            }
            if (state.currentStep == registrationSteps.donations) {
                return state.donations.id;
            }
            if (state.currentStep == registrationSteps.keepInTouch) {
                return state.keepInTouch.id;
            }
            if (state.currentStep == registrationSteps.summary) {
                return state.summary.id;
            }
        },
        hospice(state) {
            return state.hospice;
        },
        wasVisited: (state) =>
            (step) => state.visitedSteps.includes(step),
        form(state) {
            return {
                hospice: state.hospice,
                location: state.location,
                contactDetails: state.contactDetails,
                itemsToCollect: state.itemsToCollect,
                donations: state.donations,
                keepInTouch: state.keepInTouch
            }
        }
    },
    mutations: {
        setStatePart(state, { part, step }) {
            state[step] = part;
        },
        reset(state) {
            state.currentStep = registrationSteps.hospice;
            state.hospice = Object;
            state.visitedSteps = [];
            state.location = Object;
            state.contactDetails = Object;
            state.itemsToCollect = Object;
            state.donations = Object;
            state.keepInTouch = Object;
            state.summary = Object;
        },
        setStep(state, step) {
            if (state.visitedSteps.includes(registrationSteps.keepInTouch)) {
                state.currentStep = registrationSteps.summary;
                return;
            }
            state.currentStep = state.stepFlow[step];

            if (state.visitedSteps.includes(step)) {
                return;
            }
            state.visitedSteps.push(step);
        }
    },
    actions: {
        setHospice({ commit }, hospice) {
            commit("setStatePart", { part: hospice, step: registrationSteps.hospice });
        },
        setLocation({ commit }, location) {
            commit("setStatePart", { part: location, step: registrationSteps.location });
        },
        setContactDetails({ commit }, contactDetails) {
            commit("setStatePart", { part: contactDetails, step: registrationSteps.contactDetails });
        },
        setItemsToCollect({ commit }, itemsToCollect) {
            commit("setStatePart", { part: itemsToCollect, step: registrationSteps.itemsToCollect });
        },
        setDonations({ commit }, donations) {
            commit("setStatePart", { part: donations, step: registrationSteps.donations });
        },
        setKeepInTouch({ commit }, keepInTouch) {
            commit("setStatePart", { part: keepInTouch, step: registrationSteps.keepInTouch });
        },
        setSummary({ commit }, summary) {
            commit("setStatePart", { part: summary, step: registrationSteps.summary });
        },
        nextStep({ commit }, currentStep) {
            commit("setStep", currentStep)
        },
        reset({ commit }) {
            commit("reset");
        }
    }
});