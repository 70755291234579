<template>
    <base-page :model="model.heroSection" box-shadow>
        <div class="volunteer-registration-page">
            <b-row align-h="center">
                <b-col md="10" cols="12">
                    <div class="donation-page__text" v-html="model.topText"></div>
                </b-col>
            </b-row>
            <volunteer-registration-form 
            @submitForm="submit" 
            :genericErrorMessage="genericErrorMessage" 
            :model="model.formSection">
            </volunteer-registration-form>
            <div class="donation-page__text" v-html="model.bottomText"></div>
        </div>
    </base-page>
</template>
<script>
    import axios from "axios";
    import BasePage from "../Common/BasePage";
    import VolunteerRegistrationForm from "./VolunteerRegistrationForm";

    export default {
        name: "VolunteerRegistrationPage",
        components: {
            BasePage,
            VolunteerRegistrationForm
        },
        mounted() {
        },
        props: {
            model: Object
        },
        data() {
            return {
                genericErrorMessage: "",
            }
        },
        methods: {
            submit(model, state, token) {
                axios({
                method: "POST",
                url: model.submitFormUrl,
                data: { ...state, captchaToken: token },
                })
                .then(response => {
                    let url = response.data.volunteerRegisteredUrl;
                    window.location.href = url;
                })
                .catch(() => {
                    this.genericErrorMessage = model.translations["generic.Error"];
                });
            }
  },
    };
</script>
<style lang="scss">
    .volunteer-registration-page {
        &__text {
            padding-bottom: 1rem;

            p:last-child {
                padding-bottom: 0;
            }

            p {
                padding-bottom: 1rem;
            }
        }
        &--link{
            text-decoration: underline;
            &:hover{
                text-decoration: underline;
            }
        }
    }
</style>