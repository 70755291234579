<template>
  <div class="page-pagination" v-if="allPages">
    <b-row>
      <b-col cols="6" md="auto" order="1" order-md="0">
        <b-button
          @click.stop.prevent="pageChanged(currentPage - 1)"
          :disabled="currentPage === 1"
          class="button--large"
          variant="outline-primary"
        >{{previousLabel}}</b-button>
      </b-col>
      <b-col cols="12" md="4" lg="5" order="0" order-md="0" align-self="center">
        <ul class="section-title">
          <li
            @click.stop.prevent="pageChanged(item)"
            :class="{active:item == currentPage, 'no-hover': item === '...'}"
            v-for="item in paginationItems"
            :key="item"
            v-text="item"
          ></li>
        </ul>
      </b-col>
      <b-col cols="6" md="auto" order="2" order-md="0">
        <b-button
          @click.stop.prevent="pageChanged(currentPage + 1)"
          :disabled="currentPage === allPages"
          class="button--large"
          variant="success"
        >{{nextLabel}}</b-button>
      </b-col>
    </b-row>
  </div>
</template>
<script>
export default {
  name: "PagePagination",
  props: {
    allPages: Number,
    currentPage: Number,
    previousLabel: String,
    nextLabel: String
  },
  computed: {
    paginationItems() {
      const delta = 1;
      let left = this.currentPage - delta,
        right = this.currentPage + delta + 1,
        range = [],
        rangeWithDots = [],
        l;

      for (let i = 1; i <= this.allPages; i++) {
        if (i == 1 || i == this.allPages || (i >= left && i < right)) {
          range.push(i);
        }
      }

      for (let i of range) {
        if (l) {
          if (i - l === 2) {
            rangeWithDots.push(l + 1);
          } else if (i - l !== 1) {
            rangeWithDots.push("...");
          }
        }
        rangeWithDots.push(i);
        l = i;
      }

      return rangeWithDots;
    }
  },
  methods: {
    pageChanged(item) {
      if (item !== "...") {
        this.$emit("clicked", item);
      }
    }
  }
};
</script>
<style lang="scss">
@import "@/scss/_variables";
@import "@/scss/_breakpoints.scss";
.page-pagination {
  button {
    min-width: 175px;
    @include media-breakpoint-down(sm) {
      min-width: 120px;
      padding: 1rem;
    }
  }
  ul {
    display: flex;
    justify-content: space-evenly;
    list-style: none;
    margin: 0;
    font-weight: $font-weight-semibold;
    width: 100%;
    padding: 0 35px;
    li {
      float: left;
      color: $lighten-gray;
      &.active {
        color: $primary;
      }
      &:hover {
        cursor: pointer;
      }
      &.no-hover {
        &:hover {
          cursor: default;
        }
      }
    }
  }
}
</style>