<template>
  <div class="carousel-container">
    <div class="carousel-container__gradient"></div>
    <b-carousel
      :interval="model.rotationTime"
      :controls="!model.hideArrows"
      indicators
      background="#ababab"
      class="carousel"
    >
      <b-carousel-slide
        v-for="(slide, index) in model.slides"
        :key="index"
        :img-src="slide.image.url"
        class="carousel-item"
      >
        <b-container>
          <b-row>
            <b-col sm="10" offset-sm="1" lg="7" offset-lg="5" >
              <div class="carousel-item--description" v-html="slide.text"></div>
              <div v-if="slide.buttonLink != null" class="image-content-item__button">
                <b-button
                  variant="success"
                  class="button--large"
                  :href="slide.buttonLink.url"
                >{{slide.buttonLink.text}}</b-button>
              </div>
              <div
                v-if="slide.explanationText != null"
                class="image-content-item__explanation-text"
              >{{slide.explanationText}}</div>
            </b-col>
          </b-row>
        </b-container>
      </b-carousel-slide>
    </b-carousel>
  </div>
</template>
<script>
import { BCarousel, BCarouselSlide } from "bootstrap-vue";
export default {
  name: "Carousel",
  components: {
    BCarousel,
    BCarouselSlide
  },
  props: {
    model: Object
  }
};
</script>
<style lang="scss">
@import "~@/scss/_variables";
@import "~@/scss/_breakpoints";
.carousel {
  height: 100%;
  &-inner {
    height: 100%;
  }
  z-index: 100;
  &-container {
    @include media-breakpoint-up(lg) {
      margin-top: -$menu-height;
    }
    position: relative;
  }
  &-indicators {
    z-index:100;
    bottom: 1em;
    li {
      background-image: url("~@/assets/slide.png");
      width: 33px;
      height: 33px;
      background-color: transparent;
      border-top: none;
      border-bottom: none;
      opacity: initial;
      margin: 0;
      &.active {
        background-image: url("~@/assets/slide-active.png");
      }
    }
  }
  @include media-breakpoint-up(lg) {
    &-indicators {
      bottom: 2em;
    }
  }
  &-item {
    img {
      object-fit: cover;
      object-position: left center;
      height: auto!important;
      width: auto!important;
    }
    height: 100%;
    .carousel-caption {
      text-align: left;
      left: initial;
      right: initial;
      top: 170px;
      bottom: initial;
      width: 100%;
      z-index:100;
    }

    &--description {
      .image-content-item {
        &--title {
          display: block;
        }
        &--subtitle {
          display: block;
        }
        &--description {
          font-weight: $font-weight-semibold;
        }
      }
    }
  }
}
.carousel-container__gradient {
  position: absolute;
  pointer-events: none;
  z-index: 99;
  width: 100%;
  height: 270px;
  background-position-y: -30px;
  opacity: 0.5;
  background: -moz-linear-gradient(
    top,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  background: -webkit-linear-gradient(
    top,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#00ffffff',GradientType=0 );
  @include media-breakpoint-down(md) {
    height: 100%;
    background: rgba(255, 255, 255, 0.8);
    opacity: 1;
  }
}
</style>