<template>
  <div class="social-feed container">
    <div class="spinner-border text-primary" role="status" v-if="inProgress">
      <span class="sr-only">Loading...</span>
    </div>
    <div
      class="social-feed-container"
      :class="{'social-feed-container--groupped' : groupped}"
      v-if="!inProgress"
    >
      <template v-for="record in model.latestNews">
        <b-link
          v-if="groupped"
          :href="record.link"
          :key="record.name"
          target="_blank"
          class="social-news__item--link title--section color--pepper"
        >
          <i :class="`fa fa-${record.name.toLowerCase()}`"></i>
          {{record.name}}
        </b-link>
        <social-feed-tile :dto="tile" v-for="tile in record.news" :key="tile.id"></social-feed-tile>
      </template>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import SocialFeedTile from "./SocialFeedTile";

export default {
  name: "SocialFeed",
  components: {
    SocialFeedTile
  },
  mounted() {
    this.getFeed();
  },
  data() {
    return {
      model: Object,
      inProgress: Boolean
    };
  },
  props: {
    source: String,
    feeds: Array[String],
    itemsPerPage: Number,
    groupped: Boolean
  },
  methods: {
    getFeed() {
      this.inProgress = true;
      var self = this;
      axios
        .get(this.source, {
          params: {
            feedTypes: this.feeds + "",
            newsLimit: this.itemsPerPage
          }
        })
        .then(function(response) {
          self.model = response.data;
        })
        .catch(function() {})
        .then(function() {
          self.inProgress = false;
        });
    }
  }
};
</script>
<style lang="scss">
@import "@/scss/_variables";
@import "@/scss/_breakpoints.scss";
.social-feed {
  margin-bottom: 0.3125rem;
  .social-feed-container {
    column-count: 3;
    column-width: 18.5rem;
     &--groupped {
      @include media-breakpoint-down(md) {
        column-count: 1;
        column-width: 18.5rem;
      }
    }
    a {
      text-transform: uppercase;
    }
  }
}
</style>
