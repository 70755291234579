var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"home"},[_c('carousel',{staticClass:"home--carousel",attrs:{"model":_vm.dto.carousel}}),_c('section',{staticClass:"home--statistics"},[_c('statistics-section',{attrs:{"enableFreeTextArea":_vm.dto.freeTextAreaEnabled,"contentfreeTextArea":_vm.dto.freeTextAreaContent,"headerTitle":_vm.dto.heroStatistics.translations[
					'registerTree.Form.PostCodeLeftTabHeader'
				],"headerText":_vm.dto.heroStatistics.isOpenCollection
					? _vm.dto.heroStatistics.translations[
							'registerTree.Form.PostCodeLeftTabCollectionOpenInfo'
					  ]
					: _vm.dto.heroStatistics.translations[
							'registerTree.Form.PostCodeLeftTabCollectionClosedInfo'
					  ],"explanatoryText":_vm.dto.heroStatistics.translations[
					'registerTree.Form.PostCodeLeftTabExplanatoryText'
				],"tiles":_vm.dto.statistics.statistics,"links":_vm.dto.heroStatistics.links}},[_c('get-inspired',{attrs:{"model":_vm.dto.getInspired}})],1)],1),_c('image-content-section',{attrs:{"items":_vm.dto.newsAndStory,"firstImageOnLeft":false}}),_c('news',{attrs:{"dto":_vm.dto.news}}),(!_vm.dto.freeTextAreaEnabled)?_c('div',{staticClass:"home__simple-statistics-section"},[_c('simple-statistics-section',{attrs:{"model":_vm.dto.statistics,"shouldBeHidden":_vm.dto.freeTextAreaEnabled}})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }