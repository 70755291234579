<template>
        <div class="footer-bar-bottom">
      <b-container class="footer-bar-bottom__icons">
        <b-row>
          <b-col cols="auto" class="mr-auto align-self-end">
              <b-img :src="model.leftImage.url" :alt="model.leftImage.text" ></b-img>
          </b-col>
          <b-col cols="auto" class="align-self-end">
            <b-img :src="model.rightImage.url" :alt="model.rightImage.text" ></b-img>
          </b-col>
        </b-row>
      </b-container>
      <div class="footer-bar-bottom__links">
        <b-container>
          <b-row class="footer-bar-bottom__links--row justify-content-md-center justify-content-lg-start">
            <b-col cols="auto" class="align-self-center">{{model.title}}</b-col>
            <b-col cols="auto" class="align-self-center">
              <b-link
                :href="link.url"
                v-for="(link,index) in model.links"
                :key="index"
                :target="link.target"
                :class="'footer-bar-bottom__links--item'"
              >{{link.text}}</b-link>
            </b-col>
          </b-row>
        </b-container>
      </div>
    </div>
</template>

<script>
export default {
  name: "FooterBottomBar",
  props: {
    model: Object
  }
};
</script>