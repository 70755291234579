<template>
  <b-form-group :label="model.translations['orderConfirmation.PersonalDetails.SectionTitle']">
    <p>
      {{state.title}} {{state.firstName}} {{state.lastName}}
    </p>
  </b-form-group>
</template>
<script>

export default {
  name: "PersonalDetails",
  data() {
    return {
      state: {
        firstName: this.model.firstName,
        lastName: this.model.lastName,
        title: this.model.selectedTitle
      },
    };
  },
  props: {
    model: Object,
  }
};
</script>