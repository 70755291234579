<template>
	<div class="statistics-tile shadow-box">
		<div>
			<img class="statistics-tile__img" :src="model.icon" />
			<span class="statistics-tile__header" v-text="model.value"></span>
			<p v-text="model.label"></p>
		</div>
		<b-button
			v-if="model.button"
			:class="'statistics-tile__button button--large'"
			:href="model.button.link.url"
			:variant="variant"
			>{{ model.button.link.text }}</b-button
		>
	</div>
</template>

<script>
	export default {
		name: 'StatisticsTile',
		components: {},
		data: function() {
			return {};
		},
		computed: {
			variant: function() {
				return this.model.button.isOutline ? 'outline-primary' : 'primary';
			},
		},
		props: {
			model: Object,
		},
	};
</script>

<style lang="scss">
	@import '@/scss/_variables';
	@import '@/scss/_breakpoints.scss';
	.statistics-tile {
		text-align: center;
		width: 18.75rem;
		background-color: $white;
		font-weight: $font-weight-semibold;
		font-size: 1.5rem;
		margin: 0 1.0625rem;
		padding-top: 2.0625rem;
		position: relative;
		min-height: 19.25rem;
		@include media-breakpoint-down(md) {
			margin: 0 auto;
			margin-bottom: 40px;
		}
		&__header {
			top: 1.25rem;
			position: relative;
			font-size: 3rem;
			color: $primary;
		}
		&__img {
			height: 3.875rem;
			margin-right: 0.625rem;
		}
		&__button {
			margin-top: 3.5625rem;
			margin-bottom: 2.75rem;
			min-width: 10.625rem;
		}
	}
</style>
