<template>
  <div class="tree-registration">
    <carousel :model="model.carousel" class="home--carousel"></carousel>
    <div class="base-page__details color--basic background--white">
      <b-container>
        <b-row class="carousel-overlayer">
          <section class="base-page__details--full padding--text-content pt-3 pb-3 shadow-box">
            <post-code-form :dto="model.postCodeDto"></post-code-form>
            <registration-open-form v-if="registrationOpen"
                                    :model="model.openCollectionDto"
                                    :hospices="hospices"
                                    :post-code="postCode"
            ></registration-open-form>
            <registration-close-form v-if="noRegistration || fullRegistration"
                                     :hospices="hospices"
                                     :model="model.noCollectionDto"
                                     :full-registration="fullRegistration"
                                     :post-code="postCode"></registration-close-form>
            <registration-soon-form
                    v-if="registrationSoon"
                    :model="model.registrationSoon"
            ></registration-soon-form>
          </section>
        </b-row>
      </b-container>
    </div>
  </div>
</template>

<script>
import {EventBus, CHRISTMAS_TREE_POST_CODE_SUBMITED} from "@/domain/events";
import collectionStatusMixin from "@/mixins/collectionStatusMixin";
import PostCodeForm from "@/components/TreeRegistration/PostCodeForm";
import RegistrationOpenForm from "@/components/TreeRegistration/RegistrationOpenForm";
import RegistrationCloseForm from "@/components/TreeRegistration/RegistrationCloseForm";
import Carousel from "../Common/Carousel"
import RegistrationSoonForm from "@/components/TreeRegistration/RegistrationSoonForm";

export default {
  name: "TreeRegistration",
  mixins: [collectionStatusMixin],
  components: {
    RegistrationSoonForm,
    Carousel,
    PostCodeForm,
    RegistrationOpenForm,
    RegistrationCloseForm,

  },
  mounted() {
    EventBus.$on(CHRISTMAS_TREE_POST_CODE_SUBMITED, response => {
      this.collectionLookupResult = 0;
      this.$nextTick(() => {
        this.collectionLookupResult =
            response.collection.collectionLookupResult;
        this.hospices = response.collection.hospices;
        this.postCode = response.postCode;
        this.$store.ChristmasTreeStore.dispatch("reset");
      });
    });
  },
  data() {
    return {
      hospices: Array,
      postCode: ""
    };
  },
  props: {
    model: Object
  }
};
</script>
<style lang="scss">

</style>
