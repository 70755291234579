import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'mutationobserver-shim';
import './main.scss';

import * as VueGoogleMaps from 'vue2-google-maps';

import ArticleContainer from './components/ArticleContainer/ArticleContainer';
import ArticlePage from './components/Article/ArticlePage';
import BasePage from './components/Common/BasePage';
import BuisyIndicator from './components/Common/BuisyIndicator';
import BuisyIndicatorPlugin from '@/plugins/BuisyIndicator';
import ChristmasTreeStore from '@/stores/christmasTreeStore';
import ConfigStore from '@/stores/configStore';
import Contact from './components/Contact/Contact';
import ContentPage from './components/Common/ContentPage';
import DonatePage from './components/Donate/DonatePage';
import FaqPage from './components/Faq/FaqPage';
import FooterBar from './components/Footer/FooterBar';
import GlobalConfig from './components/Common/GlobalConfig';
import Home from './components/Home/Home';
import Hospice from './components/Hospice/Hospice';
import HospiceList from './components/Hospice/HospiceList';
import JHBootstrapComponents from '@/plugins/JHBootstrapComponents';
import NavigationBar from './components/Navigation/NavigationBar';
import OrderConfirmation from './components/OrderConfirmation/OrderConfirmation';
import PersonalizedDonatePage from '@/components/Donate/PersonalizedDonatePage';
import SiteAlert from '@/components/Common/SiteAlert';
import SocialSharing from 'vue-social-sharing';
import TreeRegistration from '@/components/TreeRegistration/TreeRegistration';
import Vue from 'vue';
import VueCurrencyInput from 'vue-currency-input';
import VueTelInput from 'vue-tel-input';
import Vuelidate from 'vuelidate';
import axios from 'axios';
import linkify from 'vue-linkify';
import vueScrollTo from 'vue-scrollto';
import VolunteerRegistrationPage from './components/VolunteerRegistration/VolunteerRegistrationPage'

//import RegisterTree from './components/DataCollection/RegisterTree/RegisterTree';











Vue.config.productionTip = false;

Vue.directive('linkified', linkify);

Vue.use(JHBootstrapComponents);
Vue.use(vueScrollTo);
Vue.use(BuisyIndicatorPlugin, { className: 'buisy' });
Vue.use(Vuelidate);
Vue.use(VueGoogleMaps);
Vue.use(SocialSharing);
Vue.use(VueCurrencyInput, {
	currency: 'GBP',
	locale: 'en-GB',
	precision: 2,
	allowNegative: false,
	valueRange: { min: 0, max: 9999 },
});
Vue.use(VueTelInput, {
	defaultCountry: 'GB',
	disabledFetchingCountry: true,
	validCharactersOnly: true,
	wrapperClasses: 'custom-select custom-phone',
	preferredCountries: ['GB'],
	mode: 'international',
});

axios.defaults.headers.get['Content-Type'] =
	'application/x-www-form-urlencoded';
axios.interceptors.request.use(
	function(config) {
		if (!config.url.replace(/^\//, '').startsWith('umbraco/api/')) {
			return config;
		}
		if (config.method.toLowerCase() === 'get') {
			if (!config.params) {
				config.params = {};
			}
			config.params.currentUmbracoPageId = ConfigStore.getters.currentPageId;
			config.params.culture = ConfigStore.getters.culture;
		} else {
			config.data.currentUmbracoPageId = ConfigStore.getters.currentPageId;
			config.data.culture = ConfigStore.getters.culture;
		}
		config.headers['X-Umb-Culture'] = ConfigStore.getters.culture;
		config.headers['X-Umb-PageId'] = ConfigStore.getters.currentPageId;

		return config;
	},
	function(error) {
		// Do something with request error
		return Promise.reject(error);
	}
);

new Vue({
	el: '#app',
	store: {
		ChristmasTreeStore,
		ConfigStore,
	},
	components: {
		Home,
		Contact,
		//RegisterTree,
		NavigationBar,
		FooterBar,
		BuisyIndicator,
		OrderConfirmation,
		ContentPage,
		Hospice,
		HospiceList,
		ArticlePage,
		ArticleContainer,
		GlobalConfig,
		BasePage,
		FaqPage,
		DonatePage,
		SiteAlert,
		PersonalizedDonatePage,
		TreeRegistration,
		VolunteerRegistrationPage
	},
});
