import axios from 'axios';

const apiEndpoint = 'https://maps.googleapis.com/maps/api/geocode/json';
export default {
	getAddressesCoordinates: function(googleApiKey, address, postCode) {
		let params = {
			key: googleApiKey,
			address: address,
		};

		if (postCode) {
			params = {
				key: googleApiKey,
				address: address,
				components: `postal_code:${postCode}`,
			};
		}
		return axios({
			method: 'GET',
			url: apiEndpoint,
			params: params,
		}).then((response) => response.data.results[0].geometry.location);
	},
	measureDistanceInMeters(position1, position2) {
		var R = 6371.071; // Radius of the Earth in miles
		var rlat1 = position1.lat * (Math.PI / 180); // Convert degrees to radians
		var rlat2 = position2.lat * (Math.PI / 180); // Convert degrees to radians
		var difflat = rlat2 - rlat1; // Radian difference (latitudes)
		var difflon = (position2.lng - position1.lng) * (Math.PI / 180); // Radian difference (longitudes)

		var d =
			2 *
			R *
			Math.asin(
				Math.sqrt(
					Math.sin(difflat / 2) * Math.sin(difflat / 2) +
						Math.cos(rlat1) *
							Math.cos(rlat2) *
							Math.sin(difflon / 2) *
							Math.sin(difflon / 2)
				)
			);
		return d * 1000;
	},
};
