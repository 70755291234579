<template>
    <div class="personalized-donation">
        <b-container class="padding--section personalized-donation__banner">
            <img class="image--cover" :src="model.bannerImage.url">
        </b-container>
        <b-container class="padding--section">
            <div class="donation-page base-page__details--full padding--text-content shadow-box">
                <b-row align-h="center">
                    <b-col md="10" cols="12">
                        <div v-if="model.topText" class="donation-page__text" v-html="model.topText"></div>
                    </b-col>
                </b-row>
                <donate-form :model="model.formSection"></donate-form>
                <div v-if="model.bottomText" class="donation-page__text" v-html="model.bottomText"></div>
            </div>
        </b-container>
    </div>

</template>
<script>
    import DonateForm from "./DonateForm";

    export default {
        name: "PersonalizedDonatePage",
        components: {
            DonateForm,
        },
        mounted() {
        },
        props: {
            model: Object
        }
    };
</script>
<style lang="scss">
    @import "~@/scss/_variables.scss";
    @import "~@/scss/_breakpoints.scss";

    .personalized-donation {
        &__banner{
            padding-bottom: 0;
            padding-top: 0;
        }
        .image-content-container__image {
            @include media-breakpoint-up(md) {
                height: 465px;
            }
        }

        .article-details__content-social-share {
            color: $secondary;
            text-align: right;
            position: relative;
            top: -3.625rem;
        }

        .donation-page {
            &__text {
                padding-bottom: 1rem;

                p:last-child {
                    padding-bottom: 0;
                }

                p {
                    padding-bottom: 1rem;
                }
            }
        }

        &__leading {
            .leading-image-container {
                padding-right: 0;

                > img {
                    width: 100%;
                }

            }

            .leading-cta-container {
                text-align: right;
            }
        }
    }

</style>