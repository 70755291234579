<template>
  <p class="article-date" v-text="`${prefix} ${formatedDate}`"></p>
</template>
<script>
import { longLocalDate } from "@/domain/utils";
export default {
  name: "ArticleListItem",
  components: {},
  props: {
    prefix: String,
    date: String
  },
  computed: {
    formatedDate() {
      return longLocalDate(this.$store.ConfigStore.getters.culture, this.date);
    }
  }
};
</script>
<style lang="scss">
.article-date {
  font-weight: lighter;
}
</style>