<template>
  <div class="single-related-article shadow-box">
    <img v-if="model.image" :src="model.image.url" />
    <div class="single-related-article__description">
      <a :href="model.url">
        <p class="single-related-article__title" v-text="model.title"></p>
      </a>
      <article-date :prefix="datePrefix" :date="model.createDate"></article-date>
    </div>
  </div>
</template>

<script>
import ArticleDate from "@/components/Common/ArticleDate";
export default {
  name: "SingleRelatedArticle",
  components: {
    ArticleDate
  },
  props: {
    model: Object,
    datePrefix: String
  }
};
</script>
<style lang="scss">
@import "@/scss/_variables";
.single-related-article {
  width: 415px;
  display: inline-block;
  margin-right: 42px;
  background-color: $white;
  text-align: left;
  img {
    width: 100%;
    height: 17.1875rem;
    object-fit: cover;
  }
  p {
    &.single-related-article__title {
      color: $primary;
      font-size: $font-size-lg;
      font-weight: bold;
    }
  }
  &__description {
    padding: 41px 33px;
  }
}
</style>