<template>
    <div v-if="false"></div>
</template>

<script>
    import Vue from 'vue'
    import VueAnalytics from 'vue-analytics'
    import * as VueGoogleMaps from "vue2-google-maps";

    export default {
        name: "GlobalConfig",
        beforeMount() {
            this.$store.ConfigStore.dispatch("setConfig", this.model);
            VueGoogleMaps.loadGmapApi({
                key: this.$store.ConfigStore.getters.mapKey
            });
            Vue.use(VueAnalytics, {
                id: this.model.googleAnalyticsId,
            })
            this.$ga.page(window.location.pathname);
        },
        props: {
            model: Object
        }
    };
</script>