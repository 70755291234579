<template>
  <div class="partners-section">
    <b-container>
      <b-row>
        <b-col>
          <div class="partners-section-container padding--section">
            <h5 class="title--main color--pepper">{{title}}</h5>
            <div class="partners-section-container__logos">
              <b-link
                :key="index"
                v-for="(item, index) in items"
                :href="item.link.url"
                :target="'_blank'"
              >
                <b-img class="image--scale" :src="item.logo.url" :alt="item.link.text"></b-img>
              </b-link>
            </div>
          </div>
          <hr />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
export default {
  name: "PartnersSection",
  props: {
    items: Array,
    title: String
  },
  computed: {},
  methods: {}
};
</script>
<style lang="scss">
@import "@/scss/_variables.scss";
.partners-section {
  &-container {
    &__logos {
      a {
        display: inline-block;
        height: 100px;
        max-width: 150px;
        margin-right: 20px;
      }
    }
  }
}
</style>