<template>
	<b-container class="align-items-center">
		<b-row class="statistics-section shadow-box carousel-overlayer">
			<b-col
				cols="12"
				lg="6"
				class="statistics-section__tile-left freeTextArea"
				v-if="enableFreeTextArea"
			>
				<div v-html="contentfreeTextArea"></div>
			</b-col>
			<b-col
				cols="12"
				lg="6"
				class=" statistics-section__tile-left"
			v-if="!enableFreeTextArea"
			>
				<slot></slot>
			</b-col>
			<b-col cols="12" lg="6" class="statistics-section__tile">
				<h2 class="statistics-section__tile--header" v-text="headerTitle"></h2>
				<small
					class="statistics-section__tile--text"
					v-text="headerText"
				></small>
				<div
					class="statistics-section__tile--statistics d-flex flex-column flex-sm-column flex-md-row justify-content-around"
				>
					<div v-for="tile in tiles" :key="tile.label">
						<div
							class="statistics-section__tile--statistics-bold align-items-baseline"
						>
							<img :src="tile.icon" />
							<span v-text="tile.value"></span>
						</div>
						<p v-text="tile.label"></p>
					</div>
				</div>
				<div style="margin-right: 32%;">
					<b-button
						class="button--large"
						style="justify-content: center;"
						v-for="(link, index) in links"
						:key="index"
						:variant="getVariant(index)"
						:href="link.url"
						>{{ link.text }}</b-button
					>
				</div>
				<small
					class="statistics-section__tile--explanatory"
					v-text="explanatoryText"
				></small>
			</b-col>

		</b-row>
	</b-container>
</template>
<script>
	export default {
		name: 'StatisticsSection',
		components: {},
		data: function() {
			return {};
		},
		props: {
			headerTitle: String,
			headerText: String,
			explanatoryText: String,
			tiles: Array,
			links: Array,
			enableFreeTextArea: Boolean,
			contentfreeTextArea: String,
		},
		methods: {
			getVariant: function(index) {
				if (index % 2 === 1 || this.links.length === 1)
					return 'outline-success';
				return 'success';
			},
		},
	};
</script>
<style lang="scss">
	@import '@/scss/_variables.scss';
	@import '@/scss/_breakpoints.scss';
	%semibold {
		font-size: 1.0625rem;
		font-weight: $font-weight-semibold;
	}
	.statistics-section {
		&__tile {
			padding: 1.5625rem;
			margin: 0.9375rem 0;
			text-align: center;
			& small {
				font-size: 0.75rem;
				display: block;
			}

			&--header,
			&--text {
				text-align: center;
				display: block;
			}
			&--explanatory {
				font-size: 0.8125rem;
				font-style: italic;
				margin-top: 3.125rem;
				text-align: left;
				display: block;
			}

			&--statistics {
				margin: 1.875rem 0;
				text-align: center;
				img {
					margin-right: 0.5rem;
					max-width: 2.4375rem;
				}

				& p {
					@extend %semibold;
				}

				& > div {
					margin: 0.625rem;
				}

				&-bold {
					font-size: 1.75rem;
					font-weight: bold;
					color: $primary;
					line-height: 1.5625rem;
				}
			}

			&-left {
				@include media-breakpoint-down(md) {
					border-top: solid;
					border-color: $primary;
					border-width: 0.0625rem;
				}
				@include media-breakpoint-up(lg) {
					border-right: solid;
					border-color: $primary;
					border-width: 0.0625rem;
					margin-top: 2%;
					margin-bottom: 2%;
				}
			}

			a {
				@extend %semibold;
				&.btn {
					min-width: 10.625rem;
					margin-right: 0.875rem;
				}
			}
		}
		.freeTextArea img{
			max-width: 90%;
			height: auto;
		}
	}
</style>
