<template>
    <b-form-group
            class="order-confirmation__collected-items"
            :label="model.translations['orderConfirmation.CollectedItems.SectionTitle']"
    >
        <span class="font--bold">
            <span v-text="model.translations['orderConfirmation.Email.YourItems']"></span>
            <strong> {{summary}} </strong>
            <span v-if="summary > 1" v-text="model.translations['generic.ChristmasTreePlural']"></span>
            <span v-else v-text="model.translations['generic.ChristmasTreeSingular']"></span>
            <span>. </span>
                <span v-text="descriptionPostfix"></span>
        </span>
    </b-form-group>
</template>
<script>
    export default {
        name: "CollectedItems",
        data() {
            return {
                state: {
                    collectedItems: this.model.collectedItems
                },
            };
        },
        computed: {
            summary: function () {
                return this.state.collectedItems.map(x => x.count).reduce((prev, curr) => parseInt(prev) + parseInt(curr));
            },
            descriptionPostfix: function () {
                let bigTree = this.state.collectedItems.find(x => x.id === this.model.bigTreeSku);
                let smallTree = this.state.collectedItems.find(x => x.id !== this.model.bigTreeSku);

                if (!bigTree || bigTree.count <= 0) return "";
                if (!smallTree || smallTree.count === 0)
                    return this.model.translations['orderConfirmation.Email.OnlyOneTreeWhichIsLarge']
                return this.model.translations['orderConfirmation.Email.ManyTreesIncludingLarge']

            }
        },
        props: {
            model: Object,
        }
    };
</script>