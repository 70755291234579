<template>
  <div class="article-list__item shadow-box">
    <b-row>
      <b-col sm="12" md="6" class="article-list__item__image">
        <b-link :href="model.url">
          <b-img class="image--cover" :src="model.image.url" :alt="model.image.text"></b-img>
        </b-link>
      </b-col>
      <b-col sm="12" md="6" class="article-list__item__content color--basic">
        <b-link :href="model.url">
          <h5 class="title--list">{{ model.title }}</h5>
          <article-date
              v-if="options && options.showDate"
              :prefix="options.datePrefix"
              :date="model.createDate"
          ></article-date>
          <p>{{ model.description }}</p>
          <span class="article-list__item__content__link">{{ buttonLabel }}</span>
        </b-link>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import ArticleDate from "@/components/Common/ArticleDate";

export default {
  name: "ArticleListItem",
  components: {ArticleDate},
  props: {
    model: Object,
    options: {
      datePrefix: undefined,
      showDate: false
    },
    buttonLabel: String
  }
};
</script>
<style lang="scss">
@import "~@/scss/_variables";
@import "~@/scss/_breakpoints.scss";

.article-list {
  &__item {
    margin-top: 1.75rem;

    a {
      text-decoration: none;
    }

    &__image {
      display: flex;
      align-items: center;

      @include media-breakpoint-down(sm) {
        padding-right: 0;
        padding-left: 0;
      }

      @include media-breakpoint-up(md) {
        padding-right: 0;
      }

      img {
        max-height: 17.1875rem;
      }
    }

    &__content {
      padding: 3.125rem 2.3125rem;

      h5 {
        color: $primary;
      }

      p {
        color: $main-gray;
      }

      &:hover {
        cursor: pointer;

        h5 {
          color: $secondary;
        }

        p {
          color: $secondary;
        }
        span {
          color: $secondary;
        }
        background-color: $primary;
      }

      &__link {
        text-align: right;
        display: block;
        margin-right: 1rem;
        font-size: $font-size-sm;
      }
    }

    .article-date {
      margin-top: 30px;
      margin-bottom: 30px;
    }
  }
}
</style>