<template>
  <div v-if="model.isEnabled" class="footer-bar-top-row__subscription">
    <b-img :src="model.image.url"></b-img>
    <h5
      class="footer-bar-top-row--title footer-bar-top-row__subscription--title"
    >{{model.title}}</h5>
    <p>{{model.text}}</p>
    <b-input-group class="footer-bar-top-row__subscription-subscribe">
      <b-form-input
        :placeholder="model.translations['home.Subscription.EmailAddress']"
      ></b-form-input>
      <b-input-group-append>
        <b-button :class="'footer-bar-top-row__subscription-subscribe--button'" variant="primary">
          <i class="fa fa-arrow-right" aria-hidden="true"></i>
        </b-button>
      </b-input-group-append>
    </b-input-group>
  </div>
</template>
<script>
export default {
  name: "FooterSubscription",
  props: {
    model: Object
  }
};
</script>