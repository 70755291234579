<template>
  <div class="article-container">
    <base-page :model="model.heroSection" box-shadow>
      <b-row v-if="model.featured" class="article-container__featured">
        <b-col
            cols="12"
            lg="6"
            v-if="model.featured.image"
            class="article-container__featured-image-ctn"
        >
          <b-link :href="model.featured.url">
            <img :src="model.featured.image.url"/>
          </b-link>
        </b-col>
        <b-col
            cols="12"
            :lg="model.featured.image == null ? 12 : 6"
            class="article-container__featured-txt"
        >
          <b-link :href="model.featured.url">
            <small
                class="text-uppercase text-success font-weight-semi-bold"
                v-text="model.translations['articleContainer.Featured']"
            ></small>
            <h2 class="text-success font-weight-bold" v-text="model.featured.title"></h2>
            <article-date
                :prefix="model.translations['article.RelatedArticle.DatePrefix']"
                :date="model.featured.createDate"
                :class="'color--basic'"
            ></article-date>
            <p
                v-text="model.featured.description"
                class="article-container__featured-txt-description color--basic"
            ></p>
          </b-link>
        </b-col>
      </b-row>
    </base-page>
    <b-container>
      <b-row>
        <b-col cols="12" lg="8">
          <div class="article-container__search-container" ref="articleSearch">
            <b-row>
              <b-col cols="12" lg="7" class="pl-0 pr-0">
                <b-form-input
                    class="article-container__search-input"
                    :debounce="500"
                    v-model="filter.query"
                    :placeholder="model.translations['generic.Search']"
                    @change="setQuery()"
                    type="text"
                ></b-form-input>
              </b-col>
              <b-col align-self="center" cols="12" lg="5" class="text-center text-lg-right pr-0">
                <ul class="article-container__search-years">
                  <li v-for="year in availableYears" :key="year">
                    <a
                        :class="{'active-year':year===filter.year}"
                        @click.stop.prevent="setYear(year)"
                        v-text="year"
                    ></a>
                  </li>
                </ul>
              </b-col>
            </b-row>
          </div>
          <article-list-item
              v-for="article in articles"
              :key="article.title"
              :model="article"
              :options="{showDate:true, datePrefix:'Posted'}"
              :button-label="model.translations['generic.ReadMore']"
          ></article-list-item>
          <page-pagination
              :all-pages="allPages"
              :current-page="filter.page"
              :previous-label="model.translations['generic.Previous']"
              :next-label="model.translations['generic.Next']"
              @clicked="setPage"
          ></page-pagination>
        </b-col>
        <b-col cols="12" lg="4">
          <div class="social-news">
            <h4 class="title--main" v-text="model.translations['hospice.SocialNews']"></h4>
            <social-feed
                groupped
                :source="model.socialNews.feedUrl"
                :feeds="model.socialNews.types"
                :itemsPerPage="7"
            ></social-feed>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import ArticleDate from "@/components/Common/ArticleDate";
import BasePage from "../Common/BasePage";
import PagePagination from "../Common/PagePagination";
import ArticleListItem from "../Article/ArticleListItem";
import SocialFeed from "../Common/SocialFeed";
import axios from "axios";

export default {
  name: "ArticleContainer",
  components: {
    BasePage,
    ArticleListItem,
    ArticleDate,
    PagePagination,
    SocialFeed
  },
  mounted() {
    const urlParams = new URLSearchParams(window.location.search);
    this.filter.year = urlParams.get("year");
    this.filter.page = parseInt(urlParams.get("page"));
    this.filter.query = urlParams.get("query");
    if (!this.filter.year) {
      this.filter.year = 0;
    }
    if (!this.filter.page) {
      this.filter.page = 1;
    }
    this.requestNews(false);
  },
  data() {
    return {
      filter: {
        year: 0,
        page: 0,
        query: ""
      },
      articles: [],
      allPages: 1
    };
  },
  props: {
    model: Object
  },
  methods: {
    setPage(pageNum) {
      this.filter.page = pageNum;
      this.requestNews();
    },
    setYear(year) {
      if (this.filter.year === year) {
        this.filter.year = 0;
      } else {
        this.filter.year = year;
      }
      this.setPage(1);
    },
    setQuery() {
      this.setPage(1);
    },
    requestNews(withScroll = true) {
      this.$setBuisy(true);
      var self = this;
      axios({
        method: "GET",
        url: this.model.newsUrl,
        params: {...this.filter}
      })
          .then(result => {
            self.articles = result.data.articles;
            self.allPages = result.data.allPages;
            let newurl =
                window.location.protocol +
                "//" +
                window.location.host +
                window.location.pathname +
                `?page=${self.filter.page}`;

            if (self.filter.year) {
              newurl += `&year=${self.filter.year}`;
            }

            if (self.filter.query) {
              newurl += `&query=${self.filter.query}`;
            }
            window.history.pushState({path: newurl}, "", newurl);

            if (withScroll) {
              self.$scrollTo(this.$refs["articleSearch"], 500);
            }
          })
          .catch(error => {
            throw error;
          })
          .then(() => {
            this.$setBuisy(false);
          });
    }
  },
  computed: {
    currentYear() {
      return new Date().getFullYear();
    },
    availableYears() {
      const currentYear = this.currentYear;
      let years = [];
      for (var i = currentYear; i > currentYear - 3; i--) {
        years.push(i);
      }
      return years;
    }
  }
};
</script>
<style lang="scss">
@import "@/scss/_variables";
@import "@/scss/_breakpoints.scss";

.article-container {
  .base-page__details--full {
    padding: 0;
  }

  padding-bottom: 5.4375rem;

  &__featured {
    margin: 0;

    &-image-ctn {
      overflow: hidden;
      padding: 0;

      img {
        max-width: 100%;
      }
    }

    &-txt {
      padding: 2.75rem 3rem;

      &-description {
        margin-top: 1.875rem;
      }

      &:hover {
        h2,
        small,
        a,
        p {
          color: $secondary !important;
          text-decoration: none;
        }

        cursor: pointer;
        background-color: $primary;
        color: $secondary;
      }
    }
  }

  &__search {
    &-input {
      border-radius: 50px;
    }

    &-years {
      list-style-type: none;
      margin: 0px;
      font-weight: $font-weight-semibold;
      padding: 0;
      @include media-breakpoint-down(md) {
        padding-top: 2.5rem;
      }

      li {
        display: inline;
        margin-right: 1.125rem;

        a {
          color: $lighten-gray;
          text-decoration: underline;
          cursor: pointer;

          &.active-year {
            text-decoration: underline;
            color: $primary;

            &:hover {
              color: $primary;
            }
          }

          &:hover {
            color: $primary;
          }
        }
      }

      li:last-child {
        margin-right: 0;
      }
    }
  }

  .row {
    margin: 0;
  }

  .data-collection {
    padding: 0;
  }

  small {
    font-size: $font-size-sm;
  }

  h2 {
    margin-top: 1.125rem;
    margin-bottom: 0;
  }

  .article-list__item__content {
    padding: 2.6rem 2.3125rem;
  }

  .page-pagination {
    margin-top: 5.4375rem;
  }

  .social-news {
    h4 {
      margin-bottom: 1.375rem;
    }
  }

  .article-list__item__image {
    @include media-breakpoint-up(lg) {
      padding: 0;
    }
  }
}
</style>