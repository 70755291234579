<template>
    <div class="hospice content-page hero-section">
        <div class="content-page-header hero-section-header">
            <b-row align-h="start">
                <b-col>
                    <div class="hero-section container">
                        <b-row class="justify-content-md-center">
                            <b-col v-if="model.heroSection.image" cols="12" md="6" lg="3" class="text-center">
                                <b-img
                                        :src="model.heroSection.image.url"
                                        :alt="model.heroSection.title"
                                        class="hero-section__logo"
                                ></b-img>
                            </b-col>
                            <b-col cols="12" :lg="(model.heroSection.image ? 9 : 12)">
                <span
                        v-if="model.heroSection.subtitle"
                        class="hero-section__subtitle"
                        v-text="model.heroSection.subtitle"
                ></span>
                                <h2
                                        v-if="model.heroSection.title"
                                        class="hero-section__title"
                                        v-text="model.heroSection.title"
                                ></h2>
                                <p
                                        v-if="model.heroSection.description"
                                        class="hero-section__description"
                                        v-html="model.heroSection.description"
                                ></p>
                            </b-col>
                        </b-row>
                    </div>
                </b-col>
            </b-row>
        </div>
        <div class="hospice-details hero-section-details color--basic">
            <b-container>
                <b-row class="shadow-box carousel-overlayer">
                    <b-col cols="12" lg="6">
                        <div class="hospice-details__contact">
                            <div>
                                <b-button
                                        v-if="model.contact.email"
                                        :variant="'outline-success'"
                                        :href="`mailto:${model.contact.email}`"
                                >{{ contactButtonText}}
                                </b-button>
                                <b-button
                                        v-if="collectionInProgress"
                                        :variant="'outline-success'"
                                        :href="`tel:${collectionInProgress}`"
                                >{{ model.contact.translations['hospice.Contact.PhonePrefix'] }} {{ collectionInProgress
                                    }}
                                </b-button>
                            </div>
                            <div class="hospice-details__contact__info">
                                <hospice-contact :model="model.contact"></hospice-contact>
                            </div>
                        </div>
                    </b-col>
                    <b-col cols="12" lg="6" class="hospice-details__map pr-0 pl-0">
                        <gmap-map
                                :center="mapCenter"
                                :zoom="mapZoom"
                                :options="{
                    zoomControl: false,
                    mapTypeControl: false,
                    scaleControl: false,
                    streetViewControl: false,
                    rotateControl: false,
                    fullscreenControl: false,
                    disableDefaultUi: false
                    }"
                                map-type-id="roadmap"
                                style="width: 570px; height: 408px;"
                        >
                <span>
                  <gmap-marker
                          :position="model.contact.location.coordinates"
                          :infoText="model.contact.name"
                          :clickable="false"
                          :draggable="false"
                  />
                </span>
                        </gmap-map>
                    </b-col>
                </b-row>
            </b-container>
        </div>
        <image-content-section :items="model.registrationContentItems" :firstImageOnLeft="true"></image-content-section>
        <text-content-section :items="model.textContentItems"></text-content-section>
        <partners-section
                v-if="model.supporters.length"
                :items="model.supporters"
                :title="model.translations['hospice.Supporters']"
        ></partners-section>
        <partners-section
                v-if="model.sponsors.length"
                :items="model.sponsors"
                :title="model.translations['hospice.Sponsors']"
        ></partners-section>
        <partners-section
                v-if="model.charities.length"
                :items="model.charities"
                :title="model.translations['hospice.Charities']"
        ></partners-section>
        <div v-if="model.galleryImages" class="hospice-details__gallery">
            <b-container>
                <b-row>
                    <b-col cols="12">
                        <h5 class="title--main color--pepper">{{model.translations['hospice.Gallery']}}</h5>
                        <gallery :images="model.galleryImages"></gallery>
                    </b-col>
                </b-row>
            </b-container>
        </div>
        <div class="hospice-details__article-list color--basic">
            <b-container>
                <b-row>
                    <b-col cols="12" lg="8">
                        <article-list
                                :items="model.articles"
                                :title="model.translations['hospice.LatestNews']"
                                :loadMore="model.loadMoreArticles"
                                :read-more="model.translations['generic.ReadMore']"
                        ></article-list>
                    </b-col>
                    <b-col cols="12" lg="4">
                        <div class="social-news padding--section">
                            <h4 class="title--main" v-text="model.translations['hospice.SocialNews']"></h4>
                            <social-feed
                                    groupped
                                    :source="model.socialNews.feedUrl"
                                    :feeds="model.socialNews.types"
                                    :itemsPerPage="7"
                            ></social-feed>
                        </div>
                    </b-col>
                </b-row>
            </b-container>
        </div>
    </div>
</template>
<script>
    import ImageContentSection from "../Common/ImageContentSection";
    import TextContentSection from "../Common/TextContentSection";
    import PartnersSection from "../Common/PartnersSection";
    import HospiceContact from "./HospiceContact";
    import ArticleList from "../Article/ArticleList";
    import SocialFeed from "../Common/SocialFeed";
    import Gallery from "vue-cover-gallery";

    export default {
        name: "Hospice",
        components: {
            ImageContentSection,
            TextContentSection,
            PartnersSection,
            HospiceContact,
            ArticleList,
            SocialFeed,
            Gallery
        },
        props: {
            model: Object
        },
        computed: {
            publicPath() {
                return this.$store.ConfigStore.getters.rootPath;
            },
            collectionInProgress() {
                return this.model.isCollectionInProgress && this.model.contact.phoneDuringCollection ? this.model.contact.phoneDuringCollection : this.model.contact.phone
            },
            mapCenter() {
                return this.model.contact.location.coordinates;
            },
            mapZoom() {
                return this.model.contact.location.zoom;
            },
            contactButtonText() {
                return this.model.contactButtonText?.length ? this.model.contactButtonText : `${this.model.contact.translations['hospice.Contact.EmailPrefix']} ${this.model.contact.name}`;

            }
        }
    };
</script>
<style lang="scss">
    @import "~@/scss/_variables.scss";
    @import "~@/scss/_breakpoints.scss";

    .hospice {
        &-details {
            &__map {
                .vue-map-container {
                    width: 100% !important;
                }
            }

            &__contact {
                padding: 3.125rem 3.125rem;

                a {
                    display: block;
                    margin-bottom: 0.75rem;
                    width: 100%;
                    @include media-breakpoint-up(lg) {
                        width: 75%;
                    }
                }

                &__info {
                    margin-top: 2.5rem;

                    .media {
                        margin-bottom: 1.25rem;
                    }
                }
            }

            &__article-list {
                .article-list__item__image {
                    @include media-breakpoint-down(sm) {
                        padding-right: 15px;
                        padding-left: 15px;
                    }
                }
            }
        }

        .social-feed-container {
            margin-top: 2.1875rem;
        }
    }
</style>