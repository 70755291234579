<template>
  <div class="contact">
    <base-page :model="dto.heroSection" box-shadow>
      <div class="contact__hospice-section"> 
        <h2 v-text="dto.hospiceSection.title"></h2>
        <p v-text="dto.hospiceSection.description"></p>
        <div class="contact__hospice-buttons">
          <b-button
            :href="dto.hospiceSection.link.url"
            variant="primary"
            class="button--large"
          >{{dto.hospiceSection.link.text}}</b-button>
        </div>
      </div>
      <div class="contact__form">
        <contact-form :model="dto.formSection"></contact-form>
      </div>
    </base-page>
  </div>
</template>
<script>
import BasePage from "@/components/Common/BasePage";
import ContactForm from "./ContactForm";
export default {
  name: "Contact",
  components: {
    BasePage,
    ContactForm
  },
  props: {
    dto: Object
  }
};
</script>
<style lang="scss">
.contact {
  &__hospice {
    &-buttons {
      margin-top: 2.1875rem;
    }
  }
  &__form{
      margin-top: 3.75rem
  }
}
</style>