<template>
    <div class="hospice-list content-page">
        <base-page :model="model.hero" full-width>
            <div class="hospice-list__map-container background--pepper">
                <b-container>
                    <b-row class="hospice-list__map-search">
                        <b-col cols="12" lg="5" class="pl-0">
                            <b-input-group>
                                <template v-slot:prepend>
                                    <i class="fa fa-search"></i>
                                </template>
                                <b-form-input
                                        autocomplete="off"
                                        :placeholder="model.translations['hospiceList.EnterHospiceNamePlaceHolder']"
                                        v-model="dropdownValue"
                                        @focus="showPicker()"
                                        @change="getHospiceList(true)"
                                        @blur="hidePicker()"
                                        type="text"
                                ></b-form-input>
                            </b-input-group>
                            <ul v-if="isPickerVisible" class="hospice-list__map-search-results">
                                <li v-for="hospice in hospices" :key="hospice.nodeId">
                                    <a @click.prevent.stop="getHospiceDetails(hospice.nodeId)">
                                        <span class="hospice-list__map-search-results-title"
                                              v-text="hospice.name"></span>
                                        <span v-text="hospice.address"></span>
                                    </a>
                                </li>
                                <li v-if="noHospiceFound">
                                        <span class="hospice-list__map-search-results-title"
                                              v-text="model.translations['hospiceList.HospiceNotFoundTitle']"></span>
                                    <span v-text="model.translations['hospiceList.HospiceNotFoundText']"></span>
                                </li>
                            </ul>
                        </b-col>
                    </b-row>
                </b-container>
                <b-container fluid class="hospice-list__map background--white">
                    <b-row>
                        <b-col cols="12" :lg="selectedHospice ? '8' :'12'" class="hospice-list__map-column pl-0"
                               :class="{ 'pr-0' : !selectedHospice  }">
                            <gmap-map
                                    :center="mapCenter"
                                    :zoom="mapZoom"
                                    :options="{
                    zoomControl: false,
                    mapTypeControl: false,
                    scaleControl: false,
                    streetViewControl: false,
                    rotateControl: false,
                    fullscreenControl: false,
                    disableDefaultUi: false
                    }"
                                    map-type-id="roadmap"
                                    style="width: 1100px; height: 850px"
                            >
                <span v-if="selectedHospice">
                  <gmap-marker
                          :position="selectedHospice.contact.location.coordinates"
                          :infoText="selectedHospice.name"
                          :clickable="false"
                          :draggable="false"
                  />
                </span>
                                <span v-if="!selectedHospice">
                  <gmap-marker
                          v-for="hospice in hospices"
                          :key="hospice.nodeId"
                          :position="hospice.location.coordinates"
                          :infoText="hospice.name"
                          :clickable="true"
                          :draggable="false"
                          @click="selectHospiceAndShowInfo(hospice)"
                  />
                  <gmap-info-window
                          v-if="openedHospiceInfo"
                          :options="infoWindowOptions"
                          :position="openedHospiceInfo.location.coordinates"
                          :opened="openedHospiceInfo !== undefined"
                          @closeclick="closeInfoBox"
                  ></gmap-info-window>
                </span>
                            </gmap-map>
                        </b-col>
                        <b-col
                                v-if="selectedHospice"
                                cols="12"
                                lg="4"
                                class="hospice-list__map-right"
                        >
                            <img
                                    v-if="selectedHospice.logo"
                                    class="align-self-start hospice-list__map-right__logo"
                                    :src="selectedHospice.logo.url"
                            />
                            <p class="hospice-list__map-right-title" v-text="selectedHospice.name"></p>
                            <p v-text="selectedHospice.description"></p>
                            <hospice-contact :model="selectedHospice.contact"></hospice-contact>
                            <div>
                                <b-button
                                        :href="selectedHospice.detailsPageUrl"
                                        variant="primary"
                                        class="button--large"
                                        v-text="model.translations['hospiceList.ViewHospiceDetailPageButtonText']"
                                ></b-button>
                            </div>
                        </b-col>
                    </b-row>
                </b-container>
            </div>
            <div v-if="selectedHospice" class="hospice-list__additionals padding--section">
                <b-container class="hospice-list__additionals-container">
                    <p v-html="selectedHospice.additionalInfoDescription"></p>
                </b-container>
            </div>
            <div class="hospice-list__statistics">
                <simple-statistics-section :model="model.statistics"></simple-statistics-section>
            </div>
        </base-page>
    </div>
</template>
<script>
    import axios from "axios";
    import SimpleStatisticsSection from "../Common/SimpleStatisticsSection";
    import BasePage from "../Common/BasePage";
    import HospiceContact from "./HospiceContact";
    import {isValidPostCode} from "@/domain/validationRules";

    export default {
        name: "HospiceList",
        components: {
            SimpleStatisticsSection,
            BasePage,
            HospiceContact
        },
        data() {
            return {
                isPickerVisible: false,
                hospices: Array,
                selectedHospice: undefined,
                openedHospiceInfo: undefined,
                dropdownValue: "",
                noHospiceFound: false
            };
        },
        props: {
            model: Object
        },
        mounted() {
            this.getHospiceList(false);
        },
        computed: {
            publicPath() {
                return this.$store.ConfigStore.getters.rootPath;
            },
            mapCenter() {
                if (this.selectedHospice) {
                    return this.selectedHospice.contact.location.center;
                }
                return {lng: -2.479169, lat: 54.432394};
            },
            mapZoom() {
                if (this.selectedHospice) {
                    return this.selectedHospice.contact.location.zoom;
                }
                return 6;
            },
            infoWindowOptions() {
                return {
                    content: this.openedHospiceInfo.name,
                    pixelOffset: {
                        width: 0,
                        height: -35
                    }
                };
            },
            filteredHospices() {
                if (isValidPostCode(this.dropdownValue)) {
                    this.getHospicForPostcode();
                    return this.hospices;
                } else {
                    return this.hospices.filter(hospice => {
                        return (
                            hospice.name
                                .toLowerCase()
                                .startsWith(this.dropdownValue.toLowerCase()) ||
                            hospice.address
                                .toLowerCase()
                                .startsWith(this.dropdownValue.toLowerCase())
                        );
                    });
                }
            }
        },
        methods: {
            showPicker() {
                this.isPickerVisible = true;
            },
            hidePicker() {
                var self = this;
                //need to set timeout here otherwise getHospiceDetails is not emmited when clicked
                setTimeout(function () {
                    self.isPickerVisible = false;
                }, 500);
            },
            getHospiceDetails(nodeId) {
                var self = this;
                self.$setBuisy(true);
                axios
                    .get(self.model.getHospiceByIdUrl, {
                        params: {
                            nodeId: nodeId
                        }
                    })
                    .then(function (response) {
                        self.selectedHospice = response.data.hospice;
                        self.dropdownValue = self.selectedHospice.name;
                        self.$setBuisy(false);
                    })
                    .catch(function () {
                    });
            },
            getHospiceList(showList) {
                var self = this;
                self.noHospiceFound = false;
                self.$setBuisy(true);
                axios
                    .get(self.model.searchHospiceByNameUrl + "?searchPhrase=" + self.dropdownValue?.replace("‘", "'"))
                    .then(function (response) {
                        self.hospices = response.data.hospices;
                        self.$setBuisy(false);
                        if (response.data.hospices.length == 0) {
                            self.noHospiceFound = true;
                        }
                        self.isPickerVisible = showList;
                    })
                    .catch(function () {
                    });
            },
            selectHospiceAndShowInfo(hospice) {
                this.openedHospiceInfo = hospice;
                this.getHospiceDetails(hospice.nodeId);
            },
            closeInfoBox() {
                this.openedHospiceInfo = undefined;
            }
        }
    };
</script>
<style lang="scss">
    @import "~@/scss/_variables.scss";
    @import "~@/scss/_breakpoints.scss";

    .hospice-list {
        .base-page__details {
            top: -3.125rem;
            position: relative;
        }

        .hero-section-header {
            > div.row {
                margin-bottom: 6.25rem;
            }
        }

        .fa {
            color: $lighten-green;
            font-size: 2.25rem;
            width: 2.25rem;
            text-align: center;
            line-height: 3.125rem;
        }

        &__map {
            &-column {
                @include media-breakpoint-down(md) {
                    padding: 0;
                }
            }

            &-container {
                > .container {
                    margin-bottom: 1.25rem;
                }

                .vue-map-container {
                    width: 100% !important;
                }
            }

            &-search {
                input[type="text"] {
                    color: $lighten-green;
                    background-color: $primary;
                    font-size: $font-size-lg;

                    &::placeholder {
                        color: $lighten-green;
                        opacity: 1;
                    }
                }

                &-results {
                    position: absolute;
                    z-index: 999;
                    background-color: $primary;
                    width: 100%;
                    max-height: 18.75rem;
                    color: $lighten-green;
                    font-size: $font-size-sm;
                    overflow: auto;
                    padding: 0.625rem;

                    &::-webkit-scrollbar {
                        width: 0.75rem;
                        background-color: $primary;
                        width: 0.375rem;
                        margin-right: 0.625rem;
                    }

                    &::-webkit-scrollbar-thumb {
                        border-radius: 0.625rem;
                        background-color: $lighten-green;
                    }

                    &-title {
                        color: $white;
                        font-weight: bold;
                        font-size: $font-size-base;
                    }

                    span {
                        display: block;
                    }

                    li {
                        list-style: none;
                        padding: 0.625rem;

                        &:hover {
                            border-style: solid;
                            border-width: 0.125rem;
                            cursor: pointer;
                        }
                    }
                }
            }

            &-right {
                padding: 2.5rem;
                margin-top: 3.5rem;

                > * {
                    padding-top: 1.5625rem;
                }

                &-title {
                    font-size: $font-size-lg;
                    font-weight: bold;
                    color: $primary;
                }

                li {
                    padding-top: 1.875rem;
                }

                .btn {
                    white-space: nowrap;
                    min-width: 11.25rem;
                }

                &__logo {
                    max-width: 260px;
                }
            }
        }

        &__additionals {
            background-color: $light-gray;
            // padding: 5.625rem 0;
            &-title {
                font-size: $h4-font-size;
                font-weight: bold;
            }

            &-container {
                p {
                    margin-bottom: 1rem;
                }
            }
        }

        &__statistics {
            margin-top: 2.6875rem;
        }

        .carousel-overlayer {
            display: flex;
            justify-content: center;
        }

        .statistics-tile {
            box-shadow: 0 0 0;
        }
    }
</style>