<template>
  <div class="faq-content">
    <b-row>
      <b-col lg="4" class="d-none d-lg-block">
        <div class="faq-content__menu">
          <ul>
            <li class="title--section" :key="index" v-for="(item, index) in items">
              <a
                class="color--basic"
                :class="{ 'color--pepper active': activeItem === item.key }"
                :id="`menu_${item.key}`"
                @click="scroll(item.key)"
              >{{item.title}}</a>
            </li>
          </ul>
        </div>
      </b-col>
      <b-col cols="12" lg="8" class="faq-content__sections">
        <div
          class="text-content"
          :class="{'single-without-title' : !sectionsHasTitle(item)}"
          :id="`section_${item.key}`"
          :key="index"
          v-for="(item, index) in items"
        >
          <div v-if="sectionsHasTitle(item)">
            <h5 class="title--list color--pepper">{{item.title}}</h5>
            <div class="collapse-content" :key="index" v-for="(section, index) in item.sections">
              <div class="d-block d-lg-none">
                <b-button
                  class="title--section text--uppercase padding--none button--collapse"
                  v-b-toggle="section.key"
                >{{section.title ? section.title : 'Text'}}</b-button>
                <b-collapse class="d-lg-none text-content__body" :id="section.key">
                  <div v-html="section.body"></div>
                </b-collapse>
              </div>
              <div class="d-none d-lg-block">
                <p
                  class="d-none d-lg-block title--section text--uppercase padding--none"
                >{{section.title}}</p>
                <div class="text-content__body" v-html="section.body"></div>
              </div>
            </div>
          </div>
          <section class="without-title" v-if="!sectionsHasTitle(item)">
            <b-button
              class="d-block d-lg-none title--list color--pepper button--collapse"
              v-b-toggle="item.key"
            >{{item.title}}</b-button>
            <h5 class="d-none d-lg-block title--list color--pepper">{{item.title}}</h5>
            <div class="collapse-content" :key="index" v-for="(section, index) in item.sections">
              <div class="d-block d-lg-none">
                <b-collapse class="d-lg-none text-content__body" :id="item.key">
                  <div v-html="section.body"></div>
                </b-collapse>
              </div>
              <div class="d-none d-lg-block">
                <p
                  class="d-none d-lg-block title--section text--uppercase padding--none"
                >{{section.title}}</p>
                <div class="text-content__body" v-html="section.body"></div>
              </div>
            </div>
          </section>
        </div>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { BCollapse } from "bootstrap-vue";
export default {
  name: "FaqContent",
  components: {
    BCollapse
  },
  props: {
    items: Array
  },
  data() {
    return {
      activeItem: this.items.length ? this.items[0].key : null
    };
  },
  computed: {},
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll: function() {
      const currentScrollPosition =
        window.pageYOffset || document.documentElement.scrollTop;
      if (currentScrollPosition < 0) {
        return;
      }
      this.items.find(it => {
        const el = document.getElementById(`section_${it.key}`);
        if (el.offsetTop - currentScrollPosition + 50 <= 0) {
          this.activeItem = it.key;
        }
      });
    },
    scroll: function(key) {
      var element = `#section_${key}`;
      this.$scrollTo(element, 200, { offset: -50 });
    },
    sectionsHasTitle: function(item) {
      if ((item.sections.length == 1) & !item.sections[0].title) {
        return false;
      } else {
        return true;
      }
    }
  }
};
</script>
<style lang="scss">
@import "@/scss/_variables.scss";
@import "@/scss/_breakpoints.scss";
.faq {

  &-content {
    &__menu {
      position: sticky;
      top: 2.5rem;
      padding-right: 4.125rem;
      ul {
        cursor: pointer;
        padding-inline-start: 0;
        list-style: none;
        li {
          padding-bottom: 0.625rem;
          a {
            text-decoration: none;
            font-weight: $font-weight-normal;
            &.active {
              font-weight: $font-weight-semibold;
            }
          }
        }
      }
    }
    &__sections{
      div.single-without-title:last-child {
        border-bottom: none;
      }
    }
    .text-content {
      div:last-child {
        border-bottom: none;
      }
    }
    .button--collapse {
      @include media-breakpoint-down(md) {
        background: none;
        color: inherit;
        border: none;
        cursor: pointer;
        outline: inherit;
        text-align: left;
        margin: 20px 0;
        width: 100%;
        position: relative;
        padding-right: 20px;
        &.collapsed::after {
          content: "\2193";
          color: $primary;
          width: 15px;
          font-size: 24px;
          position: absolute;
          right: 0;
        }
        &::after {
          content: "\2191";
          color: $primary;
          width: 15px;
          font-size: 24px;
          position: absolute;
          right: 0;
        }
      }
    }
    .collapse-content,
    .single-without-title {
      @include media-breakpoint-down(md) {
        border-bottom: 1px solid $lighten-gray;
      }
    }
  }
}
</style>