<template>
  <div class="base-page">
    <div class="base-page__header padding--hero background--pepper">
      <b-row align-h="start">
        <b-col>
          <div class="hero-section container">
            <b-row class="justify-content-md-center">
              <b-col v-if="model.image" cols="12" md="6" lg="3" class="text-center">
                <b-img :src="model.image.url" :alt="model.title" class="hero-section__logo"></b-img>
              </b-col>
              <b-col cols="12" :lg="(model.image ? 9 : 12)">
                <span v-if="model.subtitle" class="hero-section__subtitle" v-text="model.subtitle"></span>
                <h2 v-if="model.title" class="hero-section__title" v-text="model.title"></h2>
                <div
                  v-if="model.description"
                  class="hero-section__description"
                  v-html="model.description"
                ></div>
                <div v-if="model.links && model.links.length" class="hero-section__buttons text-center text-md-left">
                  <div v-for="(link,$index) in model.links" :key="$index">
                    <b-button
                      :href="link.url"
                      variant="outline-secondary"
                      class="button--large"
                    >{{link.text}}</b-button>
                  </div>
                </div>
              </b-col>
            </b-row>
          </div>
        </b-col>
      </b-row>
    </div>
    <div class="base-page__details color--basic background--white">
      <slot v-if="fullWidth"></slot>
      <b-container v-if="!fullWidth">
        <b-row class="carousel-overlayer">
          <section
            class="base-page__details--full padding--text-content"
            :class="{ 'shadow-box': boxShadow }"
          >
            <slot></slot>
          </section>
        </b-row>
      </b-container>
    </div>
  </div>
</template>
<script>
export default {
  name: "BasePage",
  props: {
    model: Object,
    fullWidth: {
      type: Boolean,
      default: false
    },
    boxShadow: {
      type: Boolean,
      default: false
    }
  }
};
</script>
<style lang="scss">
.base-page {
  &__details {
    &--full {
      min-width: 100%;
    }
  }
}
</style>