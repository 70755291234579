import Vue from "vue"
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        config:Object
    },
    getters: {
        currentPageId(state) {
            return state.config.currentPageId;
        },
        rootPath(state) {
            return state.config.rootPath;
        },
        culture(state) {
            return state.config.culture
        },
        mapKey(state) {
            return state.config.mapKey
        },
        defaultDonationValue(state){
            return state.config.defaultDonationValue;
        }


    },
    mutations: {
        setConfig(state, config) {
            state.config = config
        }
    },
    actions: {
        setConfig({ commit }, config) {
            commit("setConfig", config);
        },
    }
});