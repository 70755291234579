<template>
    <b-col class="register-tree__no-collection align-items-center shadow-box padding--text-content">
        <span v-html="model"></span>
    </b-col>
</template>

<script>
    export default {
        name: "RegistrationSoonForm",
        mounted: function () {
            this.$setBuisy(false);
        },
        props: {
            model: String,
        }
    }
</script>

<style lang="scss">

</style>