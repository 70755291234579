<template>
	<div class="simple-statistics">
		<p class="simple-statistics__title" v-text="model.title"></p>
		<p class="simple-statistics__description" v-html="model.description"></p>
		<statistics-collection :model="model.statistics"></statistics-collection>
	</div>
</template>

<script>
	import StatisticsCollection from './StatisticsCollection.vue';
	export default {
		name: 'SimpleStatisticsSection',
		components: {
			StatisticsCollection,
		},
		data: function() {
			return {};
		},
		props: {
			model: Object,
		},
	};
</script>

<style lang="scss">
	@import '@/scss/_variables.scss';
	.simple-statistics {
		padding-bottom: 0;
		text-align: center;
		font-weight: lighter;
		&__title {
			font-size: 2rem;
		}
		&__description {
			font-size: $font-size-sm;
		}
	}
</style>
