<template>
    <base-page :model="model.heroSection" box-shadow>
        <div class="donation-page">
            <b-row align-h="center">
                <b-col md="10" cols="12">
                    <div class="donation-page__text" v-html="model.topText"></div>
                </b-col>
            </b-row>
            <donate-form :model="model.formSection"></donate-form>
            <div class="donation-page__text" v-html="model.bottomText"></div>
        </div>
    </base-page>
</template>
<script>
    import BasePage from "../Common/BasePage";
    import DonateForm from "./DonateForm";

    export default {
        name: "DonatePage",
        components: {
            BasePage,
            DonateForm
        },
        mounted() {
        },
        props: {
            model: Object
        }
    };
</script>
<style lang="scss">
    .donation-page {
        &__text {
            padding-bottom: 1rem;

            p:last-child {
                padding-bottom: 0;
            }

            p {
                padding-bottom: 1rem;
            }
        }
        &--link{
            text-decoration: underline;
            &:hover{
                text-decoration: underline;
            }
        }
    }
</style>