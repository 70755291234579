<template>
  <div class="footer-bar">
    <div class="footer-bar-top">
      <b-container>
        <b-row class="footer-bar-top-row">
          <b-col v-if="footer.subscription.isEnabled" sm="12" lg="4" class="mr-auto align-self-top">
            <footer-subscription :model="footer.subscription"></footer-subscription>
          </b-col>
          <b-col sm="12" :lg="linksColumnWidth" class="align-self-top footer-bar-top-row__links">
            <footer-link-section :model="footer.linksSection"></footer-link-section>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <footer-bottom-bar :model="footer.bottomRow"></footer-bottom-bar>
  </div>
</template>
<script>
import FooterBottomBar from "./FooterBottomBar";
import FooterSubscription from "./FooterSubscription";
import FooterLinkSection from "./FooterLinkSection";
export default {
  name: "FooterBar",
  components: {
    FooterBottomBar,
    FooterSubscription,
    FooterLinkSection
  },
  computed:{
    linksColumnWidth : function(){
      return this.footer.subscription.isEnabled ? 8 : 12;
    }
  },
  props: {
    footer: Object
  }
};
</script>
<style lang="scss">
@import "./FooterBar";
</style>