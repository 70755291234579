<template>
<base-page :model="model.heroSection">
    <faq-content :items="model.items"></faq-content>
</base-page>
</template>
<script>
import BasePage from "../Common/BasePage";
import FaqContent from "./FaqContent";
export default {
  name: "FaqPage",
   components: {
    BasePage,
    FaqContent
    },
  props: {
    model: Object
  }
};
</script>