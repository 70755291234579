<template>
    <div class="contact-form" ref="contactform">
        <div v-if="!submitted">
            <h2 v-text="model.title"></h2>
            <div v-html="model.description"></div>
            <b-container fluid class="contact-form__form">
                <form @submit.stop.prevent="submit" autocomplete="chrome-off">
                    <b-row>
                        <b-col cols="12">
                            <p class="font--smaller text-right">
                                {{model.translations['contact.MandatoryFieldsExplanation']}}
                            </p>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="12" lg="6">
                            <b-form-input
                                    type="text"
                                    :name="uniqueIdFor('name')"
                                    autocomplete="chrome-off"
                                    v-model="state.name"
                                    :max="maxNameLength"
                                    :placeholder="model.translations['contact.NamePlaceholder']"
                                    :state="validationState($v.state.name)"
                            ></b-form-input>
                            <b-form-invalid-feedback
                                    v-if="$v.state.name.$dirty && !$v.state.name.required"
                            >{{model.translations['generic.FieldRequired']}}
                            </b-form-invalid-feedback>
                            <b-form-invalid-feedback
                                    v-if="$v.state.name.$dirty && !$v.state.name.maxLength"
                            >{{model.translations['generic.ValueTooLong']}} {{maxNameLength}}
                            </b-form-invalid-feedback>
                        </b-col>
                        <b-col cols="12" lg="6">
                            <vue-tel-input :name="uniqueIdFor('phone')"
                                           v-model="$v.state.phone.$model"
                                           :placeholder="model.translations['contact.PhonePlaceholder']"
                                           autocomplete="chrome-off"
                                           :wrapper-classes="{'is-invalid': validationState($v.state.phone) === false, 'custom-phone':true}"></vue-tel-input>
                            <b-form-invalid-feedback
                                    :state="validationState($v.state.phone)"
                            >{{model.translations['generic.FieldRequired']}}
                            </b-form-invalid-feedback>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="12" lg="6">
                            <b-form-input
                                    type="text"
                                    autocomplete="chrome-off"
                                    v-model="state.email"
                                    :name="uniqueIdFor('email')"
                                    :placeholder="model.translations['contact.EmailPlaceholder']"
                                    :state="validateEmail($v.state.email,$v.state.emailConfirmation)"
                            ></b-form-input>
                            <b-form-invalid-feedback
                                    v-if="$v.state.email.$dirty && !$v.state.email.required"
                            >{{model.translations['generic.FieldRequired']}}
                            </b-form-invalid-feedback>
                            <b-form-invalid-feedback
                                    v-if="$v.state.email.$dirty && !$v.state.email.email"
                            >{{model.translations['generic.FieldEmailInvalid']}}
                            </b-form-invalid-feedback>
                        </b-col>
                        <b-col cols="12" lg="6">
                            <b-form-input
                                    type="text"
                                    autocomplete="chrome-off"
                                    :name="uniqueIdFor('email-repeat')"
                                    v-model="$v.state.emailConfirmation.$model"
                                    :placeholder="model.translations['contact.NameRepeatPlaceholder']"
                                    :state="validationState($v.state.emailConfirmation)"
                            ></b-form-input>
                            <b-form-invalid-feedback
                                    v-if="$v.state.emailConfirmation.$dirty &&!$v.state.emailConfirmation.required"
                            >{{model.translations['generic.FieldRequired']}}
                            </b-form-invalid-feedback>
                            <b-form-invalid-feedback
                                    v-if="$v.state.emailConfirmation.$dirty && !$v.state.emailConfirmation.sameAsEmail"
                            >{{model.translations['generic.PersonalDetails.EmailsNotMatch']}}
                            </b-form-invalid-feedback>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="12" lg="6">
                            <b-form-input
                                    class="contact-form__form-postCode"
                                    type="text"
                                    autocomplete="chrome-off"
                                    :name="uniqueIdFor('postcode')"
                                    v-model.trim="state.postCode"
                                    :placeholder="model.translations['contact.PostcodePlaceholder']"
                                    :state="validationState($v.state.postCode)"
                            ></b-form-input>
                            <b-form-invalid-feedback
                                    v-if="$v.state.postCode.$dirty && !$v.state.postCode.required"
                            >{{model.translations['generic.FieldRequired']}}
                            </b-form-invalid-feedback>
                            <b-form-invalid-feedback
                                    v-if="$v.state.postCode.$dirty && !$v.state.postCode.isValidPostCode"
                            >{{model.translations['contact.Validation.PostCodeInvalid']}}
                            </b-form-invalid-feedback>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="12" lg="6">
                            <b-form-group :label="model.translations['contact.Message']">
                                <b-form-textarea
                                        maxlength="500"
                                        type="number"
                                        rows="3"
                                        v-model="state.message"
                                        autocomplete="chrome-off"
                                        :name="uniqueIdFor('message')"
                                        :placeholder="model.translations['contact.Message.Placeholder']"
                                        :state="validationState($v.state.message)"
                                ></b-form-textarea>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="12" lg="6" class="text-center text-lg-left">
                            <b-button
                                    type="submit"
                                    variant="primary"
                                    class="button--large"
                            >{{model.translations['contact.SubmitButton']}}
                            </b-button>
                            <div class="invalid-feedback" v-if="genericErrorMessage" v-text="genericErrorMessage"></div>
                        </b-col>
                    </b-row>
                </form>
                <b-alert
                        v-model="hasValidationError"
                        class="position-fixed fixed-top m-0 rounded-0"
                        style="z-index: 2000; text-align: center;"
                        variant="danger"
                        dismissible
                >
                    {{model.translations["generic.Error"]}}
                </b-alert>
            </b-container>
        </div>
        <b-container fluid class="contact-form__form" v-if="submitted">
            <b-row>
                <b-col>
                    <div v-html="model.thankYouMessage"></div>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>
<script>
    import axios from "axios";
    import ValidationMixin from "@/mixins/validationMixin";
    import {required, email, maxLength, sameAs} from "vuelidate/lib/validators";
    import {isValidPostCode} from "@/domain/validationRules";
    import {nameFieldMaxLength} from "@/domain/constants"
    import {load} from "recaptcha-v3";
    import {BAlert} from "bootstrap-vue";

    export default {
        name: "ContactForm",
        mixins: [ValidationMixin],
        components: {
            BAlert
        },
        mounted() {
            load(this.model.reCaptcha.siteKey).then(recaptcha => {
                this.recaptcha = recaptcha;
            });
            this.maxNameLength = nameFieldMaxLength;
        },
        data() {
            return {
                maxNameLength: null,
                requestId: Date.now().toString(16),
                recaptcha: Object,
                submitted: false,
                hasValidationError: false,
                genericErrorMessage: "",
                state: {
                    name: "",
                    phone: "",
                    email: "",
                    emailConfirmation: "",
                    postCode: "",
                    message: "",
                }
            };
        },
        validations: {
            state: {
                name: {
                    required,
                    maxLength: maxLength(nameFieldMaxLength)
                },
                phone: {
                    required
                },
                email: {
                    required,
                    email
                },
                emailConfirmation: {required, email, sameAsEmail: sameAs("email")},
                postCode: {
                    required,
                    isValidPostCode
                },
                message: {
                    required
                }
            }
        },
        props: {
            model: Object
        },
        methods: {
            scrollToFirstInvalidElement() {
                let element = this.$el.getElementsByClassName("is-invalid")[0];
                if (element != undefined) {
                    this.$scrollTo(element, 200, {offset: -80});
                }
            },
            submit() {
                this.hasValidationError = false;
                this.genericErrorMessage = "";
                this.$v.$touch();
                if (this.$v.$anyError) {
                    this.$nextTick(() => this.scrollToFirstInvalidElement());
                    this.hasValidationError = true;
                    return;
                }
                this.$setBuisy(true);
                this.recaptcha.execute("register").then(token => {
                    axios({
                        method: "POST",
                        url: this.model.submitUrl,
                        data: {...this.state, captchaToken: token}
                    })
                        .then(() => {
                            this.submitted = true;
                            this.$setBuisy(false);
                            this.$nextTick(() => {
                                this.$scrollTo(this.$refs["contactform"], 200, {
                                    easing: "ease-in",
                                    force: true,
                                    cancelable: true,
                                    offset: -300,
                                    x: false,
                                    y: true
                                });
                            });
                        })
                        .catch(() => {
                            this.hasValidationError = true;
                            this.genericErrorMessage = this.model.translations["generic.Error"];
                            this.$setBuisy(false);
                        });
                });
            },
            uniqueIdFor(value) {
                return `${value}_${this.requestId}`;
            }
        }
    };
</script>
<style lang="scss">
    @import "~@/scss/_variables.scss";
    @import "~@/scss/_breakpoints.scss";

    .contact-form {
        &__form {
            .col-12 {
                padding-bottom: 1rem;
            }

            padding-left: 0;
            padding-right: 0;
            margin-top: 3.75rem;

            .form-group {
                .custom-radio {
                    &:last-of-type {
                        padding-bottom: 0;
                    }
                }
            }

            legend,
            label {
                font-weight: $font-weight-semibold;
            }

            &-postCode {
                text-transform: uppercase;
            }
        }
    }
</style>