export default {
    methods: {
        validationState(model) {
            if (model.$dirty) return !model.$error;
            return null;
        },
        validateEmail(email, confirmEmail){
            if(!email.$dirty || !confirmEmail.$dirty)
                return null;
            return !email.$error;
        }
    }
}