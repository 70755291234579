<template>
  <div class="news home-section">
    <div>
      <p class="home-section__title" v-text="dto.title"></p>
      <p class="home-section__description" v-html="dto.description"></p>
      <p class="news__social-icons">
        <a
          :href="socialMedia.value"
          v-for="socialMedia in dto.enabledSocialMedia"
          :key="socialMedia.text"
        >
          <i :class="`fa fa-${socialMedia.text.toLowerCase()}`"></i>
        </a>
      </p>
      <social-feed :source="dto.socialFeedUrl" :feeds="enabledFeeds" :itemsPerPage="dto.itemsPerPage"></social-feed>
      <b-link class="news__cta" :href="dto.showMoreLink.url">{{dto.showMoreLink.text}}</b-link>
    </div>
  </div>
</template>

<script>
import SocialFeed from "../Common/SocialFeed";
export default {
  name: "News",
  components: {
    SocialFeed
  },
  mounted() {},
  data() {
    return {};
  },
  props: {
    dto: Object
  },
  computed: {
    enabledFeeds() {
      return this.dto.enabledSocialMedia.map(x => {
        return x.text.toLowerCase();
      });
    }
  },
  methods: {}
};
</script>
<style lang="scss">
@import "@/scss/_variables";
.news {
  background-color: $light-gray;
  text-align: center;
  &__cta{
    margin-top:2rem;
    display: block;
    position: relative;
  }
  &__social-icons {
    font-size: 1.6875rem;
    color: $primary;
    margin: 2.125rem 0 4.25rem;
    & i {
      padding: 0 1.5625rem;
    }
  }
}
</style>
