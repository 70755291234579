export const availableCollectionLookups = {
    none: 0,
    registrationClosed: 1,
    registrationOpen: 2,
    registrationFull: 3,
    registrationSoon: 4
}

export const collectionType = {
    none: 0,
    christmassTree: 1,
    jumble: 2,
}