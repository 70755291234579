import { availableCollectionLookups } from "@/domain/enums";

export default {
  data() {
    return {
      collectionLookupResult: availableCollectionLookups.none,
    };
  },
  computed: {
    registrationOpen: function(){
      return this.collectionLookupResult === availableCollectionLookups.registrationOpen;
    },
    noRegistration: function(){
      return this.collectionLookupResult === availableCollectionLookups.registrationClosed;
    },
    fullRegistration: function(){
      return this.collectionLookupResult === availableCollectionLookups.registrationFull;
    },
    registrationSoon:function(){
      return this.collectionLookupResult === availableCollectionLookups.registrationSoon;
    },
    registrationNotSet:function(){
      return this.collectionLookupResult === availableCollectionLookups.none;
    }
  }
}